export default
{
  "name": "production",
  "debug": false,
  "testing": false,
  "swissdata": {
    "host": "https://api.swissdata.io",
    "apiKey": "645493defdec"
  },
  "clientUrl": "https://viewer.scanways.io"
}
