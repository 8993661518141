
import * as THREE from "../../libs/three.js/build/three.module.js";
import {Annotation} from "../Annotation.js";
import {Utils} from "../utils.js";
import {CameraMode} from "../defines.js";
import {EventDispatcher} from "../EventDispatcher.js";
import { SdioFetch, SdioState} from "./Sdiofetch.js";

export class AnnotationTool extends EventDispatcher{
	constructor (viewer) {
		super();

		this.viewer = viewer;
		this.renderer = viewer.renderer;

		this.sg = new THREE.SphereGeometry(0.1);
		this.sm = new THREE.MeshBasicMaterial({color: 0xff0000});
		this.s = new THREE.Mesh(this.sg, this.sm);
	}

	startInsertion (args = {}) {
		let domElement = this.viewer.renderer.domElement;

		let annotation = new Annotation({
			position: [0, 0, 0],
			title: "Annotation Title",
			description: `Annotation Description`
		});
		this.dispatchEvent({type: 'start_inserting_annotation', annotation: annotation});

		const annotations = this.viewer.scene.annotations;
		annotations.add(annotation);

		let callbacks = {
			cancel: null,
			finish: null,
		};

		let insertionCallback = (e) => {
			if (e.button === THREE.MOUSE.LEFT) {
				callbacks.finish();
				
				// TODO : Test multiple sélection
				if (window.activeMultiAnnotations) {
					this.addAnnotationsMultiple(); // Next Point
				}
			} else if (e.button === THREE.MOUSE.RIGHT) {
				callbacks.cancel();
				if (window.activeMultiAnnotations) {
					 viewer.scene.scene.remove(this.s);
					 for (const a of this.viewer.scene.annotations.children) {
						SdioFetch.saveAnnotation(a);
					 }
				}
			}
		};

		callbacks.cancel = e => {
			annotations.remove(annotation);

			domElement.removeEventListener('mouseup', insertionCallback, true);
		};

		callbacks.finish = e => {
			domElement.removeEventListener('mouseup', insertionCallback, true);
		};

		domElement.addEventListener('mouseup', insertionCallback, true);

		let drag = (e) => {
			let I = Utils.getMousePointCloudIntersection(
				e.drag.end, 
				e.viewer.scene.getActiveCamera(), 
				e.viewer, 
				e.viewer.scene.pointclouds,
				{pickClipped: true});

			if (I) {
				this.s.position.copy(I.location);
				let annotationPosition = new THREE.Vector3(I.location.x, I.location.y, I.location.z + 1);
				let cameraPosition = {x: annotationPosition.x + 15, y: annotationPosition.y + 6, z: annotationPosition.z + 6};
				annotation.cameraPosition = cameraPosition;
				annotation.cameraTarget = annotationPosition;
				annotation.position.copy(annotationPosition);
			}
		};

		let drop = (e) => {
			let originPosition = new THREE.Vector3(annotation.position.x, annotation.position.y, annotation.position.z - 1);
			annotation.position.copy(originPosition);
			if (!window.activeMultiAnnotations) {
				viewer.scene.scene.remove(this.s);
			}
			this.s.removeEventListener("drag", drag);
			this.s.removeEventListener("drop", drop);
			this.viewer.inputHandler.toggleSelection(object);
		};

		this.s.addEventListener('drag', drag);
		this.s.addEventListener('drop', drop);

		this.viewer.scene.scene.add(this.s);
		this.viewer.inputHandler.startDragging(this.s);

		return annotation;
	}
	
	update(){
		// let camera = this.viewer.scene.getActiveCamera();
		// let domElement = this.renderer.domElement;
		// let measurements = this.viewer.scene.measurements;

		// const renderAreaSize = this.renderer.getSize(new THREE.Vector2());
		// let clientWidth = renderAreaSize.width;
		// let clientHeight = renderAreaSize.height;

	}

	render(){
		//this.viewer.renderer.render(this.scene, this.viewer.scene.getActiveCamera());
	}

	addAnnotationsMultiple() {
		if (window.activeMultiAnnotations) {
			$('#menu_measurements').next().slideDown(); ;
			let annotation = this.viewer.annotationTool.startInsertion();
			window.pointIncNumber++;

			// Set default value
			annotation.title = window.pointIncNumber;
			annotation.codeString = '-';
			annotation.code = 0;
			annotation.projectId = SdioState.getProjectId();

			// let annotationsRoot = $("#jstree_scene").jstree().get_json("annotations");
			// let jsonNode = annotationsRoot.children.find(child => child.data.uuid === annotation.uuid);
			// $.jstree.reference(jsonNode.id).deselect_all();
			// $.jstree.reference(jsonNode.id).select_node(jsonNode.id);
		}
	}
};
