
export default
{ "features":
    [
        {
            "type": "Feature",
            "geometry": {
              "type": "LineString",
              "coordinates": [
                [2643790.1970607704,1130523.3588545227,682.217999992967],
                [2643791.481,1130523.085,682.24500000298],
                [2643796.3540000003,1130521.966,682.226999996305],
                [2643801.21,1130520.773,682.297000004053],
                [2643806.046,1130519.505,682.293999997973],
                [2643810.863,1130518.163,682.317000014782],
                [2643815.6580000003,1130516.747,682.338000012636],
                [2643820.431,1130515.256,682.357000006437],
                [2643825.18,1130513.693,682.378999991417],
                [2643829.904,1130512.0559999999,682.42799998641],
                [2643834.603,1130510.347,682.401000006199],
                [2643839.274,1130508.564,682.422000004053],
                [2643843.917,1130506.709,682.431000007391],
                [2643848.53,1130504.78,682.443999989033],
                [2643853.1119999997,1130502.778,682.500000013113],
                [2643857.661,1130500.703,682.473000003099],
                [2643862.176,1130498.555,682.48400001049],
                [2643866.656,1130496.336,682.494999988079],
                [2643871.1,1130494.044,682.496000005007],
                [2643875.5069999998,1130491.682,682.512000007629],
                [2643879.875,1130489.25,682.558000011444],
                [2643884.204,1130486.747,682.533999992609],
                [2643888.492,1130484.176,682.542000008821],
                [2643892.739,1130481.537,682.582999987602],
                [2643896.942,1130478.8290000001,682.569999976158],
                [2643901.102,1130476.055,682.573999984264],
                [2643905.2169999997,1130473.2140000002,682.572999997139],
                [2643909.285,1130470.308,682.582000000477],
                [2643913.309,1130467.341,682.589000029564],
                [2643917.2909999997,1130464.317,682.597999973297],
                [2643921.234,1130461.243,682.623999996185],
                [2643925.143,1130458.124,682.611999971867],
                [2643929.019,1130454.966,682.613000018597],
                [2643932.8680000002,1130451.774,682.619000000954],
                [2643936.693,1130448.554,682.632999999523],
                [2643944.288,1130442.049,682.641000015736],
                [2643951.837,1130435.491,682.657999975681],
                [2643959.374,1130428.918,682.661999983788],
                [2643966.91,1130422.345,682.679999990463],
                [2643974.446,1130415.772,682.693999989033],
                [2643981.983,1130409.199,682.712999982834],
                [2643989.519,1130402.626,682.731999976635],
                [2643997.055,1130396.053,682.738000018597],
                [2644004.5919999997,1130389.48,682.745999975204],
                [2644012.128,1130382.9070000001,682.753999991417],
                [2644019.664,1130376.335,682.762999994755],
                [2644027.201,1130369.762,682.772999985218],
                [2644034.739,1130363.1909999999,682.781999988556],
                [2644042.279,1130356.622,682.795999987125],
                [2644049.821,1130350.055,682.740999979973],
                [2644057.364,1130343.491,682.837999982834],
                [2644064.91,1130336.928,682.845000011921],
                [2644072.455,1130330.365,682.859999997616],
                [2644080,1130323.803,682.882000012398],
                [2644087.545,1130317.24,682.901999993324],
                [2644095.09,1130310.6770000001,682.925999982357],
                [2644102.635,1130304.114,682.944999976158],
                [2644110.181,1130297.551,682.965000016689],
                [2644117.726,1130290.989,682.985999984741],
                [2644125.2709999997,1130284.426,683.004999978542],
                [2644132.816,1130277.863,683.020000023842],
                [2644140.361,1130271.3,683.037999970913],
                [2644147.906,1130264.737,683.056999964714],
                [2644155.452,1130258.175,683.07499997139],
                [2644162.997,1130251.612,683.089999957085],
                [2644170.542,1130245.049,683.099000020027],
                [2644178.087,1130238.486,683.115999979973],
                [2644185.6319999998,1130231.923,683.137000007629],
                [2644193.1769999997,1130225.361,683.191999955177],
                [2644200.723,1130218.798,683.178000016212],
                [2644208.268,1130212.235,683.199000043869],
                [2644215.813,1130205.672,683.222999973297],
                [2644223.358,1130199.1090000002,683.242000026703],
                [2644230.903,1130192.547,683.26399998188],
                [2644238.4480000003,1130185.9840000002,683.283000035286],
                [2644245.994,1130179.4209999999,683.303000016212],
                [2644253.539,1130172.858,683.327999992371],
                [2644261.0840000003,1130166.295,683.352999968529],
                [2644268.629,1130159.733,683.372000021935],
                [2644276.174,1130153.17,683.395000023842],
                [2644283.72,1130146.607,683.418000025749],
                [2644291.265,1130140.044,683.433000011444],
                [2644298.81,1130133.481,683.462999982834],
                [2644306.355,1130126.919,683.459000034332],
                [2644313.9,1130120.356,683.477999968529],
                [2644321.445,1130113.793,683.492999954224],
                [2644328.991,1130107.23,683.513000054359],
                [2644336.536,1130100.6670000001,683.528999967575],
                [2644344.081,1130094.104,683.549999995232],
                [2644351.626,1130087.5420000001,683.569999976158],
                [2644359.171,1130080.979,683.602999968529],
                [2644366.716,1130074.416,683.60900001049],
                [2644374.262,1130067.853,683.630999965668],
                [2644381.807,1130061.29,683.653000040054],
                [2644389.352,1130054.728,683.676999969482],
                [2644396.897,1130048.165,683.70100001812],
                [2644404.442,1130041.602,683.724000020027],
                [2644411.9869999997,1130035.039,683.745000047684],
                [2644419.5330000003,1130028.476,683.767000002861],
                [2644427.078,1130021.914,683.790000004768],
                [2644434.623,1130015.351,683.811999959946],
                [2644437.1135626235,1130013.1845901262,683.819999976158],
                [2644442.168,1130008.788,683.834999961853],
                [2644449.713,1130002.225,683.85900001049],
                [2644457.258,1129995.662,683.878999991417],
                [2644464.804,1129989.1,683.900000019073],
                [2644472.349,1129982.537,683.921999974251],
                [2644479.894,1129975.9740000002,683.936000032425],
                [2644487.4390000002,1129969.4109999998,683.959000034332],
                [2644494.984,1129962.848,683.977999968529],
                [2644502.529,1129956.2859999998,684.000000042915],
                [2644510.075,1129949.723,684.018999977112],
                [2644517.62,1129943.16,684.041000051498],
                [2644525.165,1129936.597,684.052999897003],
                [2644532.71,1129930.034,684.088999910355],
                [2644540.255,1129923.472,684.092000003934],
                [2644547.801,1129916.909,684.113000001788],
                [2644555.346,1129910.346,684.139999996901],
                [2644562.891,1129903.783,684.180000003457],
                [2644570.4359999998,1129897.22,684.182999994636],
                [2644577.981,1129890.658,684.209000002623],
                [2644585.526,1129884.095,684.230999987602],
                [2644593.0719999997,1129877.5320000001,684.25399998951],
                [2644600.617,1129870.969,684.287000011683],
                [2644608.162,1129864.406,684.297999989271],
                [2644615.707,1129857.844,684.318],
                [2644623.252,1129851.281,684.338999997854],
                [2644630.797,1129844.7179999999,684.359999995708],
                [2644638.3430000003,1129838.155,684.380000006437],
                [2644645.8880000003,1129831.592,684.40399999547],
                [2644653.433,1129825.03,684.426000010252],
                [2644660.978,1129818.467,684.450000029087],
                [2644668.523,1129811.904,684.465000014782],
                [2644676.068,1129805.341,684.485999982834],
                [2644683.614,1129798.778,684.512999992847],
                [2644691.159,1129792.216,684.534000020504],
                [2644698.704,1129785.653,684.555999975681],
                [2644706.249,1129779.09,684.575000029087],
                [2644713.794,1129772.527,684.59799997139],
                [2644721.339,1129765.9640000002,684.623999994278],
                [2644728.885,1129759.402,684.643999975205],
                [2644736.43,1129752.8390000002,684.664000015736],
                [2644743.975,1129746.2759999998,684.679999988556],
                [2644751.52,1129739.713,684.696000020981],
                [2644759.065,1129733.15,684.70699999857],
                [2644766.611,1129726.588,684.720999997139],
                [2644774.156,1129720.025,684.73999999094],
                [2644781.701,1129713.462,684.75700001049],
                [2644789.246,1129706.899,684.776999991417],
                [2644796.7909999997,1129700.336,684.791999977112],
                [2644804.336,1129693.774,684.812000017643],
                [2644811.8819999998,1129687.211,684.826000016212],
                [2644819.4269999997,1129680.648,684.841999989033],
                [2644826.972,1129674.085,684.858000021458],
                [2644834.517,1129667.522,684.874999981403],
                [2644842.062,1129660.96,684.901000004292],
                [2644849.607,1129654.397,684.923000019074],
                [2644857.153,1129647.834,684.942000012875],
                [2644864.6980000003,1129641.271,684.962000053406],
                [2644872.2430000002,1129634.7079999999,684.985000055313],
                [2644879.788,1129628.146,685.00700001049],
                [2644887.333,1129621.5829999999,685.023000042915],
                [2644894.878,1129615.02,685.053000014305],
                [2644902.424,1129608.457,685.069999974251],
                [2644909.969,1129601.894,685.089000027657],
                [2644917.514,1129595.331,685.158000033379],
                [2644925.059,1129588.769,685.13300005722],
                [2644932.6040000003,1129582.206,685.156999986649],
                [2644940.149,1129575.643,685.176000040054],
                [2644947.695,1129569.08,685.194999974251],
                [2644955.24,1129562.517,685.214999955177],
                [2644962.785,1129555.955,685.232999961853],
                [2644970.33,1129549.392,685.25500003624],
                [2644977.875,1129542.8290000001,685.278000038147],
                [2644985.421,1129536.2659999998,685.301999967575],
                [2644992.966,1129529.703,685.324999969482],
                [2645000.511,1129523.1409999998,685.344999950409],
                [2645008.056,1129516.578,685.365999978066],
                [2645015.601,1129510.015,685.388000052452],
                [2645023.1459999997,1129503.452,685.408999960899],
                [2645030.692,1129496.889,685.428000014305],
                [2645038.2369999997,1129490.327,685.446999948502],
                [2645045.7819999997,1129483.764,685.475999992371],
                [2645053.327,1129477.201,685.492999952316],
                [2645060.872,1129470.638,685.515000026703],
                [2645068.417,1129464.075,685.576999943733],
                [2645075.963,1129457.513,685.558000009537],
                [2645083.508,1129450.95,685.579999964714],
                [2645091.0530000003,1129444.387,685.602999966621],
                [2645098.598,1129437.824,685.625000041008],
                [2645106.143,1129431.261,685.645000021935],
                [2645113.688,1129424.699,685.66300002861],
                [2645121.234,1129418.136,685.686000030518],
                [2645128.779,1129411.5729999999,685.696999948502],
                [2645136.324,1129405.01,685.716000001907],
                [2645143.869,1129398.447,685.734000008583],
                [2645151.414,1129391.885,685.748999994278],
                [2645158.9590000003,1129385.322,685.768999975205],
                [2645166.505,1129378.759,685.78800002861],
                [2645174.05,1129372.196,685.806999962807],
                [2645181.595,1129365.633,685.826000016212],
                [2645189.14,1129359.071,685.844999950409],
                [2645191.6310757594,1129356.9041437763,685.849999945641],
                [2645196.685,1129352.508,685.865999978066],
                [2645204.23,1129345.945,685.88000003624],
                [2645211.776,1129339.382,685.895000021935],
                [2645219.321,1129332.8190000001,685.916000049591],
                [2645226.866,1129326.257,685.934999983788],
                [2645234.411,1129319.6940000001,685.959000032425],
                [2645241.956,1129313.131,685.994000118256],
                [2645249.502,1129306.568,686.004000108719],
                [2645257.047,1129300.005,686.026999993444],
                [2645264.5919999997,1129293.443,686.055000005484],
                [2645272.137,1129286.88,686.08099999857],
                [2645279.682,1129280.317,686.101000009298],
                [2645287.227,1129273.754,686.129000006437],
                [2645294.773,1129267.1909999999,686.15299999547],
                [2645302.318,1129260.629,686.180000005484],
                [2645309.863,1129254.0659999999,686.192],
                [2645317.4080000003,1129247.503,686.210000006676],
                [2645324.953,1129240.94,686.233000008583],
                [2645332.498,1129234.377,686.23899999094],
                [2645340.044,1129227.815,686.254999993563],
                [2645347.589,1129221.252,686.273999987364],
                [2645355.134,1129214.689,686.296999989271],
                [2645362.679,1129208.126,686.317999987125],
                [2645370.224,1129201.563,686.340999989033],
                [2645377.769,1129195.001,686.361000029564],
                [2645385.315,1129188.438,686.383999971867],
                [2645392.86,1129181.875,686.408000020504],
                [2645400.405,1129175.312,686.432000009537],
                [2645407.95,1129168.749,686.451000003338],
                [2645415.495,1129162.187,686.469999997139],
                [2645423.04,1129155.624,686.494999973297],
                [2645430.586,1129149.061,686.514000026703],
                [2645438.131,1129142.498,686.534000007629],
                [2645445.676,1129135.935,686.552000014305],
                [2645453.221,1129129.373,686.571000008106],
                [2645460.766,1129122.81,686.571000008106],
                [2645468.312,1129116.247,686.58099999857],
                [2645475.857,1129109.6840000001,686.599000005245],
                [2645483.402,1129103.121,686.623999981403],
                [2645490.9469999997,1129096.558,686.651999978542],
                [2645498.492,1129089.996,686.670999972344],
                [2645506.037,1129083.433,686.692],
                [2645513.583,1129076.87,686.710999993801],
                [2645521.128,1129070.307,686.730999974728],
                [2645528.673,1129063.744,686.748999981403],
                [2645536.2180000003,1129057.182,686.772999970436],
                [2645543.7630000003,1129050.619,686.788000015736],
                [2645551.308,1129044.0559999999,686.806000022411],
                [2645558.8540000003,1129037.493,686.823999969482],
                [2645566.399,1129030.93,686.841999976158],
                [2645573.944,1129024.368,686.864000050545],
                [2645581.489,1129017.805,686.882999984741],
                [2645589.034,1129011.242,686.904000012398],
                [2645596.579,1129004.679,686.925999967575],
                [2645604.125,1128998.116,686.946999995232],
                [2645611.67,1128991.554,686.966000048637],
                [2645619.215,1128984.991,686.984999982834],
                [2645626.76,1128978.428,687.009000031471],
                [2645634.305,1128971.865,687.027000038147],
                [2645641.85,1128965.3020000001,687.047000019074],
                [2645649.3959999997,1128958.74,687.070999948502],
                [2645656.941,1128952.1770000001,687.090000001907],
                [2645664.486,1128945.614,687.111000029564],
                [2645672.031,1128939.051,687.12900003624],
                [2645679.576,1128932.488,687.147999970436],
                [2645687.122,1128925.926,687.172000019074],
                [2645694.667,1128919.363,687.192],
                [2645702.212,1128912.8,687.211999980927],
                [2645709.7569999998,1128906.237,687.229999987602],
                [2645717.3019999997,1128899.674,687.25299998951],
                [2645724.847,1128893.112,687.272999970436],
                [2645732.393,1128886.549,687.297000019074],
                [2645739.938,1128879.986,687.320999948502],
                [2645747.483,1128873.423,687.341000048637],
                [2645755.028,1128866.86,687.356000034332],
                [2645762.5730000003,1128860.298,687.369000045776],
                [2645770.1180000002,1128853.735,687.384999958992],
                [2645777.664,1128847.172,687.399000017166],
                [2645785.2090000003,1128840.6090000002,687.417999951363],
                [2645792.754,1128834.0459999999,687.435999958038],
                [2645800.299,1128827.4840000002,687.445999948502],
                [2645807.844,1128820.9209999999,687.461000053406],
                [2645815.389,1128814.358,687.478999940872],
                [2645822.935,1128807.795,687.494999973297],
                [2645830.48,1128801.232,687.514000026703],
                [2645838.025,1128794.67,687.530999986649],
                [2645845.57,1128788.107,687.555999962807],
                [2645853.115,1128781.544,687.583999959946],
                [2645860.66,1128774.981,687.609999982834],
                [2645868.206,1128768.418,687.637999979973],
                [2645875.751,1128761.856,687.665999977112],
                [2645883.296,1128755.293,687.687000004768],
                [2645890.841,1128748.73,687.705000011444],
                [2645898.386,1128742.1670000001,687.724999992371],
                [2645905.931,1128735.604,687.739000050545],
                [2645913.477,1128729.0420000001,687.759000031471],
                [2645921.022,1128722.479,687.769999949455],
                [2645928.567,1128715.916,687.792000023842],
                [2645936.1119999997,1128709.353,687.849999992371],
                [2645943.6569999997,1128702.79,687.830999938965],
                [2645951.203,1128696.228,687.84999998641],
                [2645958.748,1128689.665,687.878000013351],
                [2645966.293,1128683.102,687.900999985457],
                [2645973.838,1128676.539,687.925999991417],
                [2645981.383,1128669.976,687.94199999404],
                [2645988.9280000003,1128663.414,687.94199999404],
                [2645996.474,1128656.851,687.983000002623],
                [2646004.019,1128650.288,688.008999995709],
                [2646011.5640000002,1128643.725,688.02799998951],
                [2646019.109,1128637.162,688.055999986649],
                [2646026.654,1128630.5990000002,688.08400001359],
                [2646034.199,1128624.037,688.115999989033],
                [2646041.745,1128617.4740000002,688.147999994278],
                [2646049.29,1128610.9109999998,688.167999975205],
                [2646056.835,1128604.348,688.170999996185],
                [2646064.38,1128597.785,688.180999986649],
                [2646071.925,1128591.223,688.193000010967],
                [2646079.47,1128584.66,688.209999970913],
                [2646087.016,1128578.097,688.229000024319],
                [2646094.5609999998,1128571.534,688.253000013351],
                [2646102.106,1128564.971,688.272999994278],
                [2646109.651,1128558.409,688.297999970436],
                [2646117.196,1128551.846,688.322000019074],
                [2646124.741,1128545.283,688.347999982357],
                [2646132.287,1128538.72,688.365999989033],
                [2646139.832,1128532.1570000001,688.389999978066],
                [2646147.377,1128525.595,688.411999992847],
                [2646154.922,1128519.0320000001,688.43599998188],
                [2646162.4669999997,1128512.469,688.457000009537],
                [2646170.0130000003,1128505.906,688.48099999857],
                [2646177.558,1128499.3429999999,688.502000026226],
                [2646185.103,1128492.781,688.518999986172],
                [2646192.648,1128486.2179999999,688.546999983311],
                [2646200.193,1128479.655,688.56200002861],
                [2646207.738,1128473.092,688.570999972343],
                [2646215.284,1128466.529,688.587000004768],
                [2646222.829,1128459.967,688.606000058174],
                [2646230.374,1128453.404,688.624999992371],
                [2646237.919,1128446.841,688.641999952316],
                [2646245.464,1128440.278,688.662000052452],
                [2646253.009,1128433.715,688.692000023842],
                [2646260.555,1128427.153,688.712000004768],
                [2646268.1,1128420.59,688.731000058174],
                [2646275.645,1128414.027,688.74800001812],
                [2646283.19,1128407.4640000002,688.769999973297],
                [2646290.735,1128400.9009999998,688.794000021935],
                [2646298.28,1128394.3390000002,688.812999956131],
                [2646305.826,1128387.7759999998,688.832000009537],
                [2646313.371,1128381.213,688.853999964714],
                [2646320.9159999997,1128374.65,688.884000055313],
                [2646328.461,1128368.087,688.899000041008],
                [2646336.006,1128361.525,688.924999944687],
                [2646343.551,1128354.962,688.940000049591],
                [2646351.097,1128348.399,688.960000030518],
                [2646358.642,1128341.836,688.975000016212],
                [2646366.187,1128335.273,688.990000001907],
                [2646373.732,1128328.711,689.004999987602],
                [2646381.277,1128322.148,689.024000041008],
                [2646388.8230000003,1128315.585,689.055999986649],
                [2646396.3680000002,1128309.022,689.067000023842],
                [2646403.913,1128302.459,689.085000030518],
                [2646411.458,1128295.897,689.106000058174],
                [2646419.003,1128289.334,689.128999940872],
                [2646426.548,1128282.771,689.151000015259],
                [2646434.094,1128276.2079999999,689.174999944687],
                [2646441.639,1128269.645,689.195999972343],
                [2646449.184,1128263.0829999999,689.217],
                [2646456.7290000003,1128256.52,689.243999950409],
                [2646464.274,1128249.957,689.264999978066],
                [2646471.819,1128243.394,689.28100001049],
                [2646479.365,1128236.831,689.299999944687],
                [2646486.91,1128230.269,689.315999977112],
                [2646494.455,1128223.706,689.332000009537],
                [2646502,1128217.143,689.356000058174],
                [2646509.545,1128210.58,689.374999992371],
                [2646517.09,1128204.017,689.397999994278],
                [2646524.636,1128197.455,689.414000026703],
                [2646532.181,1128190.892,689.437999956131],
                [2646539.726,1128184.3290000001,689.457000009537],
                [2646547.2709999997,1128177.7659999998,689.481000058174],
                [2646554.816,1128171.203,689.503000013351],
                [2646562.361,1128164.6409999998,689.52699994278],
                [2646569.9069999997,1128158.078,689.547999970436],
                [2646577.452,1128151.515,689.573999993324],
                [2646584.997,1128144.952,689.595000020981],
                [2646592.542,1128138.389,689.618000022888],
                [2646600.087,1128131.826,689.634000055313],
                [2646607.6319999998,1128125.264,689.648999921799],
                [2646615.1780000003,1128118.701,689.668999902725],
                [2646622.723,1128112.138,689.690000049591],
                [2646630.268,1128105.575,689.711999885559],
                [2646637.813,1128099.012,689.740000001907],
                [2646645.358,1128092.45,689.762000076294],
                [2646652.904,1128085.887,689.773999921799],
                [2646660.449,1128079.324,689.806999914169],
                [2646667.994,1128072.761,689.824000112534],
                [2646675.539,1128066.1979999999,689.848999969482],
                [2646683.0840000003,1128059.636,689.881999996305],
                [2646690.629,1128053.0729999999,689.881999996305],
                [2646698.175,1128046.51,689.881999996305],
                [2646705.72,1128039.947,689.881999996305],
                [2646713.265,1128033.384,689.952000004053],
                [2646720.81,1128026.822,689.964999985695],
                [2646728.355,1128020.259,689.964999985695],
                [2646735.9,1128013.696,689.998000007868],
                [2646743.446,1128007.133,689.998000007868],
                [2646750.991,1128000.57,690.03599999547],
                [2646758.536,1127994.008,690.058000010252],
                [2646766.081,1127987.445,690.058000010252],
                [2646773.626,1127980.882,690.12199999094],
                [2646781.171,1127974.3190000001,690.146999996901],
                [2646788.7169999997,1127967.756,690.125000011921],
                [2646796.262,1127961.1940000001,690.144000005722],
                [2646803.807,1127954.631,690.158999991417],
                [2646811.352,1127948.068,690.179999989271],
                [2646826.442,1127934.942,690.221000027657],
                [2646833.988,1127928.38,690.242999982834],
                [2646841.5330000003,1127921.817,690.263000023365],
                [2646849.078,1127915.254,690.282000017166],
                [2646856.623,1127908.6909999999,690.282000017166],
                [2646864.168,1127902.128,690.316999983788],
                [2646871.714,1127895.5659999999,690.33899999857],
                [2646879.259,1127889.003,690.33899999857],
                [2646886.804,1127882.44,690.33899999857],
                [2646894.349,1127875.877,690.381999981403],
                [2646901.894,1127869.314,690.407000017166],
                [2646909.4390000002,1127862.752,690.43299998045],
                [2646916.985,1127856.189,690.449000012875],
                [2646924.53,1127849.626,690.468999993801],
                [2646932.075,1127843.063,690.488999974728],
                [2646939.62,1127836.5,690.511999976635],
                [2646947.165,1127829.938,690.524000000954],
                [2646954.71,1127823.375,690.542000007629],
                [2646962.256,1127816.812,690.542000007629],
                [2646969.801,1127810.249,690.542000007629],
                [2646977.346,1127803.686,690.542000007629],
                [2646984.891,1127797.124,690.602999997139],
                [2646992.4359999998,1127790.561,690.622999978066],
                [2646999.981,1127783.998,690.658000004292],
                [2647007.527,1127777.435,690.658000004292],
                [2647015.0719999997,1127770.872,690.690999996662],
                [2647022.617,1127764.31,690.719000053406],
                [2647030.162,1127757.747,690.719000053406],
                [2647037.707,1127751.1840000001,690.800999951363],
                [2647045.252,1127744.621,690.800999951363],
                [2647052.798,1127738.058,690.800999951363],
                [2647060.3430000003,1127731.496,690.800999951363],
                [2647067.8880000003,1127724.933,690.841000032425],
                [2647075.433,1127718.37,690.867999982834],
                [2647082.978,1127711.807,690.926999998093],
                [2647090.524,1127705.244,690.915999960899],
                [2647098.069,1127698.682,690.938000035286],
                [2647105.614,1127692.119,690.938000035286],
                [2647113.159,1127685.5559999999,690.982999992371],
                [2647120.704,1127678.993,691.002999973297],
                [2647128.249,1127672.43,691.020000052452],
                [2647135.795,1127665.868,691.030999970436],
                [2647143.34,1127659.305,691.055000019074],
                [2647150.885,1127652.742,691.084999990463],
                [2647158.43,1127646.179,691.094000053406],
                [2647165.975,1127639.616,691.094000053406],
                [2647173.52,1127633.053,691.132999968529],
                [2647181.066,1127626.491,691.147000026703],
                [2647188.611,1127619.928,691.208999943733],
                [2647196.156,1127613.365,691.188000035286],
                [2647203.701,1127606.8020000001,691.204999995232],
                [2647211.246,1127600.239,691.224000048637],
                [2647218.7909999997,1127593.6770000001,691.224000048637],
                [2647226.337,1127587.114,691.267999958992],
                [2647233.8819999998,1127580.551,691.282999944687],
                [2647241.4269999997,1127573.988,691.305999946594],
                [2647248.972,1127567.425,691.32600004673],
                [2647256.517,1127560.863,691.32600004673],
                [2647264.062,1127554.3,691.367999982834],
                [2647271.608,1127547.737,691.394000005722],
                [2647286.6980000003,1127534.611,691.435000014305],
                [2647294.2430000002,1127528.049,691.461999964714],
                [2647301.788,1127521.486,691.474000048637],
                [2647309.333,1127514.923,691.474000048637],
                [2647316.879,1127508.36,691.520000052452],
                [2647324.424,1127501.797,691.54399998188],
                [2647331.969,1127495.235,691.560999941826],
                [2647339.514,1127488.672,691.581000041962],
                [2647347.059,1127482.1090000002,691.607999992371],
                [2647354.605,1127475.5459999999,691.614000034332],
                [2647362.15,1127468.983,691.614000034332],
                [2647369.695,1127462.4209999999,691.657000017166],
                [2647377.24,1127455.858,691.657000017166],
                [2647384.785,1127449.295,691.685000014305],
                [2647392.33,1127442.732,691.685000014305],
                [2647399.876,1127436.169,691.715000006437],
                [2647407.421,1127429.607,691.729000005007],
                [2647414.966,1127423.044,691.75299999404],
                [2647422.511,1127416.481,691.773000004768],
                [2647430.056,1127409.918,691.796000006676],
                [2647437.601,1127403.355,691.815999987602],
                [2647445.147,1127396.793,691.835999998331],
                [2647452.692,1127390.23,691.863000008345],
                [2647460.2369999997,1127383.6670000001,691.884000006199],
                [2647467.7819999997,1127377.104,691.903],
                [2647475.327,1127370.541,691.928000005961],
                [2647482.872,1127363.979,691.949000003815],
                [2647490.418,1127357.416,691.974999996901],
                [2647497.963,1127350.853,691.993999990702],
                [2647505.508,1127344.29,692.013000014305],
                [2647513.0530000003,1127337.727,692.037000003338],
                [2647520.598,1127331.165,692.061999979496],
                [2647528.143,1127324.602,692.080999973297],
                [2647535.6890000002,1127318.039,692.108999970436],
                [2647543.234,1127311.476,692.12300002861],
                [2647550.779,1127304.913,692.147000017643],
                [2647558.324,1127298.351,692.180000010014],
                [2647565.869,1127291.788,692.190999987602],
                [2647573.415,1127285.225,692.214999976635],
                [2647580.96,1127278.662,692.233999970436],
                [2647588.505,1127272.0990000002,692.254999998093],
                [2647596.05,1127265.537,692.278999987125],
                [2647603.595,1127258.9740000002,692.300000014782],
                [2647611.14,1127252.4109999998,692.32499999094],
                [2647618.6859999998,1127245.848,692.316999974728],
                [2647626.231,1127239.285,692.365000012398],
                [2647633.776,1127232.723,692.383000019074],
                [2647641.321,1127226.16,692.402000012875],
                [2647648.866,1127219.597,692.422999980927],
                [2647656.411,1127213.034,692.445999982834],
                [2647663.957,1127206.471,692.475000026703],
                [2647671.502,1127199.909,692.483999970436],
                [2647679.047,1127193.346,692.503000023842],
                [2647686.5919999997,1127186.783,692.522000017643],
                [2647694.137,1127180.22,692.537999990463],
                [2647701.682,1127173.6570000001,692.552000048637],
                [2647709.228,1127167.095,692.562999966621],
                [2647716.773,1127160.5320000001,692.575999978066],
                [2647724.318,1127153.969,692.59200001049],
                [2647731.863,1127147.406,692.603999975205],
                [2647739.4080000003,1127140.8429999999,692.615000012398],
                [2647746.953,1127134.28,692.628000023842],
                [2647754.499,1127127.7179999999,692.651000025749],
                [2647762.044,1127121.155,692.684999945641],
                [2647769.589,1127114.592,692.697000029564],
                [2647777.134,1127108.029,692.723000052452],
                [2647784.679,1127101.466,692.741000059128],
                [2647792.225,1127094.904,692.758999946594],
                [2647799.77,1127088.341,692.784000041962],
                [2647807.315,1127081.778,692.786999943733],
                [2647814.86,1127075.215,692.799999955177],
                [2647822.405,1127068.652,692.812000039101],
                [2647829.95,1127062.09,692.84000003624],
                [2647837.496,1127055.527,692.833999994278],
                [2647845.0409999997,1127048.9640000002,692.866000059128],
                [2647852.586,1127042.4009999998,692.888000014305],
                [2647860.131,1127035.838,692.906000020981],
                [2647867.676,1127029.2759999998,692.922999980927],
                [2647875.221,1127022.713,692.940999987602],
                [2647882.767,1127016.15,692.960000041008],
                [2647890.312,1127009.587,692.978000047684],
                [2647897.857,1127003.024,692.988000038147],
                [2647905.402,1126996.462,693.011999967575],
                [2647912.9469999997,1126989.899,693.031999948502],
                [2647920.492,1126983.336,693.046000006676],
                [2647928.038,1126976.773,693.065999987602],
                [2647935.583,1126970.21,693.083999994278],
                [2647943.128,1126963.648,693.105000021935],
                [2647950.673,1126957.085,693.118999960899],
                [2647958.2180000003,1126950.522,693.138000014305],
                [2647965.7630000003,1126943.959,693.161999943733],
                [2647973.309,1126937.396,693.177000048637],
                [2647980.8540000003,1126930.834,693.195999982834],
                [2647988.399,1126924.271,693.210999968529],
                [2647995.944,1126917.7079999999,693.220000031471],
                [2648003.489,1126911.145,693.233000042915],
                [2648011.034,1126904.582,693.253000023842],
                [2648018.58,1126898.02,693.27699995327],
                [2648026.125,1126891.457,693.292000058174],
                [2648033.67,1126884.894,693.315999987602],
                [2648041.215,1126878.331,693.337000015259],
                [2648048.76,1126871.768,693.355000021935],
                [2648056.306,1126865.206,693.376999977112],
                [2648063.851,1126858.643,693.398000004768],
                [2648071.3959999997,1126852.08,693.426000001907],
                [2648078.941,1126845.517,693.450000050545],
                [2648086.486,1126838.9540000001,693.460000041008],
                [2648094.031,1126832.392,693.500000002861],
                [2648101.577,1126825.8290000001,693.504999998093],
                [2648109.122,1126819.2659999998,693.528999996901],
                [2648116.667,1126812.703,693.549999994755],
                [2648124.212,1126806.14,693.570000005484],
                [2648131.7569999998,1126799.578,693.58999998641],
                [2648139.3019999997,1126793.015,693.605999989033],
                [2648146.848,1126786.452,693.625000012636],
                [2648154.393,1126779.889,693.64299998951],
                [2648161.938,1126773.326,693.663000000238],
                [2648169.483,1126766.764,693.677999985933],
                [2648177.028,1126760.201,693.69900001359],
                [2648184.5730000003,1126753.638,693.72099999857],
                [2648192.119,1126747.075,693.737999988318],
                [2648199.664,1126740.512,693.764000011206],
                [2648207.2090000003,1126733.95,693.778999996901],
                [2648214.754,1126727.387,693.801999998808],
                [2648222.299,1126720.824,693.825000000715],
                [2648229.844,1126714.261,693.850999993801],
                [2648237.39,1126707.6979999999,693.873999995709],
                [2648244.935,1126701.136,693.893999976635],
                [2648252.48,1126694.5729999999,693.914000017166],
                [2648260.025,1126688.01,693.929999989986],
                [2648267.57,1126681.447,693.951000017643],
                [2648275.116,1126674.884,693.97299997282],
                [2648282.661,1126668.322,693.994000000477],
                [2648290.206,1126661.759,694.013999981403],
                [2648297.751,1126655.196,694.034000021935],
                [2648305.296,1126648.633,694.057000023842],
                [2648312.841,1126642.07,694.080000025749],
                [2648320.387,1126635.507,694.108000022888],
                [2648327.932,1126628.945,694.123000008583],
                [2648335.477,1126622.382,694.145000023365],
                [2648343.022,1126615.8190000001,694.165999991417],
                [2648350.567,1126609.256,694.18999998045],
                [2648358.1119999997,1126602.693,694.207],
                [2648365.6580000003,1126596.131,694.225999993801],
                [2648373.203,1126589.568,694.244000000477],
                [2648380.748,1126583.005,694.267000002384],
                [2648388.293,1126576.442,694.282999975205],
                [2648395.838,1126569.879,694.303000015736],
                [2648403.383,1126563.317,694.322000009537],
                [2648410.9280000003,1126556.754,694.345000011444],
                [2648418.474,1126550.1909999999,694.361000043869],
                [2648426.019,1126543.628,694.381999952316],
                [2648433.5640000002,1126537.065,694.404000026703],
                [2648441.109,1126530.502,694.422999960899],
                [2648448.654,1126523.939,694.473999959946],
                [2648456.199,1126517.377,694.499999982834],
                [2648463.744,1126510.814,694.478000027657],
                [2648471.29,1126504.251,694.494999987602],
                [2648478.835,1126497.688,694.55200002861],
                [2648486.38,1126491.125,694.542999965668],
                [2648493.925,1126484.562,694.542999965668],
                [2648501.47,1126478,694.578999979019],
                [2648509.015,1126471.437,694.596000058174],
                [2648516.56,1126464.874,694.609999997139],
                [2648524.106,1126458.311,694.634000045776],
                [2648531.651,1126451.748,694.647999984741],
                [2648539.196,1126445.185,694.662999970436],
                [2648546.741,1126438.623,694.682999951363],
                [2648554.286,1126432.06,694.707],
                [2648561.831,1126425.497,694.725000006676],
                [2648569.376,1126418.9340000001,694.744999987602],
                [2648576.922,1126412.371,694.764000041008],
                [2648584.4669999997,1126405.808,694.787999970436],
                [2648592.012,1126399.245,694.807000023842],
                [2648599.557,1126392.683,694.823999983788],
                [2648607.102,1126386.12,694.840999943733],
                [2648614.647,1126379.557,694.861000043869],
                [2648622.192,1126372.994,694.879999978066],
                [2648629.7369999997,1126366.4309999999,694.897999984741],
                [2648637.2830000003,1126359.868,694.915999991417],
                [2648644.828,1126353.3059999999,694.932000023842],
                [2648652.373,1126346.743,694.953999979019],
                [2648659.918,1126340.18,694.968999964714],
                [2648667.463,1126333.617,694.993000013351],
                [2648675.008,1126327.054,695.007999999046],
                [2648682.5530000003,1126320.491,695.027999979973],
                [2648690.099,1126313.929,695.047999960899],
                [2648697.644,1126307.366,695.067000014305],
                [2648705.1890000002,1126300.803,695.085000020981],
                [2648712.734,1126294.24,695.103999955177],
                [2648720.279,1126287.6770000001,695.123000008583],
                [2648727.824,1126281.114,695.14199994278],
                [2648735.369,1126274.5520000001,695.160999996185],
                [2648742.915,1126267.989,695.182000023842],
                [2648750.46,1126261.426,695.202000004768],
                [2648758.005,1126254.863,695.225000006676],
                [2648765.55,1126248.3,695.241000039101],
                [2648773.095,1126241.737,695.259000045776],
                [2648780.64,1126235.174,695.277999979973],
                [2648788.185,1126228.612,695.305000049591],
                [2648795.731,1126222.049,695.315000040054],
                [2648803.276,1126215.486,695.330000025749],
                [2648810.821,1126208.923,695.360000116348],
                [2648818.366,1126202.36,695.364999992371],
                [2648825.911,1126195.797,695.39400003624],
                [2648833.456,1126189.235,695.407000047684],
                [2648841.001,1126182.672,695.407000047684],
                [2648856.0919999997,1126169.5459999999,695.407000047684],
                [2648871.182,1126156.42,695.513000008106],
                [2648969.269,1126071.103,695.513000008106],
                [2648984.359,1126057.978,695.513000008106],
                [2648999.449,1126044.852,695.513000008106],
                [2649029.63,1126018.601,695.956000026703],
                [2649059.81,1125992.3490000002,695.956000026703],
                [2649067.356,1125985.787,696.042999979496],
                [2649074.901,1125979.2240000002,696.042999979496],
                [2649082.446,1125972.6609999998,696.083999988079],
                [2649105.081,1125952.972,696.083999988079],
                [2649150.352,1125913.595,696.083999988079],
                [2649180.5330000003,1125887.344,696.32199996376],
                [2649188.078,1125880.781,696.32199996376],
                [2649195.623,1125874.2179999999,696.32199996376],
                [2649255.984,1125821.716,696.518999990463],
                [2649286.165,1125795.4640000002,696.518999990463],
                [2649293.71,1125788.9009999998,696.518999990463],
                [2649331.435,1125756.087,696.518999990463],
                [2649376.706,1125716.71,696.884],
                [2649437.067,1125664.207,696.884],
                [2649497.4280000003,1125611.705,696.884],
                [2649512.519,1125598.5790000001,697.186000045776],
                [2649535.154,1125578.89,697.186000045776],
                [2649557.79,1125559.202,697.282999986172],
                [2649565.335,1125552.639,697.282999986172],
                [2649572.88,1125546.076,697.282999986172],
                [2649580.425,1125539.513,697.326999985933],
                [2649587.97,1125532.951,697.341000014305],
                [2649595.515,1125526.388,697.341000014305],
                [2649603.06,1125519.825,697.369000011444],
                [2649610.606,1125513.262,697.388999992371],
                [2649618.151,1125506.699,697.413999998331],
                [2649625.696,1125500.136,697.436000013113],
                [2649633.241,1125493.5729999999,697.436000013113],
                [2649640.786,1125487.011,697.436000013113],
                [2649648.331,1125480.4479999999,697.504000001907],
                [2649655.876,1125473.885,697.504999989033],
                [2649663.422,1125467.322,697.523999982834],
                [2649670.9669999997,1125460.759,697.523999982834],
                [2649678.512,1125454.196,697.561999970436],
                [2649686.057,1125447.634,697.591000014305],
                [2649693.602,1125441.071,697.591000014305],
                [2649701.147,1125434.508,697.62300001955],
                [2649708.692,1125427.945,697.62300001955],
                [2649716.238,1125421.382,697.658999973297],
                [2649723.7830000003,1125414.8190000001,697.678000026703],
                [2649731.328,1125408.257,697.69999998188],
                [2649738.873,1125401.6940000001,697.69999998188],
                [2649746.418,1125395.131,697.735000008106],
                [2649753.963,1125388.568,697.743000024319],
                [2649761.508,1125382.005,697.777000003815],
                [2649769.0530000003,1125375.442,697.794000023365],
                [2649776.599,1125368.88,697.818000012398],
                [2649784.144,1125362.317,697.848999970913],
                [2649791.6890000002,1125355.754,697.865999990463],
                [2649799.234,1125349.1909999999,697.88700001812],
                [2649806.779,1125342.628,697.88700001812],
                [2649814.324,1125336.065,697.88700001812],
                [2649821.869,1125329.502,697.956000023842],
                [2649829.415,1125322.94,697.976000004768],
                [2649836.96,1125316.377,697.990999990463],
                [2649844.505,1125309.814,698.005000048637],
                [2649852.05,1125303.251,698.005000048637],
                [2649859.595,1125296.688,698.061999970436],
                [2649867.14,1125290.125,698.058000021935],
                [2649874.685,1125283.563,698.084000044823],
                [2649882.231,1125277,698.104000025749],
                [2649889.776,1125270.437,698.119000011444],
                [2649897.321,1125263.874,698.143999987602],
                [2649904.866,1125257.311,698.163999968529],
                [2649912.411,1125250.748,698.163999968529],
                [2649919.956,1125244.186,698.232999974251],
                [2649927.501,1125237.623,698.229000025749],
                [2649935.047,1125231.06,698.249000006676],
                [2649942.5919999997,1125224.497,698.270000034332],
                [2649950.137,1125217.9340000001,698.296999984741],
                [2649957.682,1125211.371,698.296999984741],
                [2649965.227,1125204.8090000001,698.296999984741],
                [2649972.772,1125198.246,698.359999948502],
                [2649980.317,1125191.683,698.377999955177],
                [2649987.863,1125185.12,698.397000008583],
                [2649995.4080000003,1125178.557,698.436000042915],
                [2650002.953,1125171.994,698.436000042915],
                [2650010.498,1125165.4309999999,698.436000042915],
                [2650018.043,1125158.869,698.489000016213],
                [2650025.588,1125152.3059999999,698.489000016213],
                [2650033.133,1125145.743,698.527000003815],
                [2650040.6780000003,1125139.18,698.553000026703],
                [2650048.224,1125132.617,698.574000054359],
                [2650055.769,1125126.054,698.574000054359],
                [2650063.3140000002,1125119.492,698.627999955177],
                [2650070.859,1125112.929,698.632999950409],
                [2650078.404,1125106.366,698.653000050545],
                [2650085.949,1125099.803,698.653000050545],
                [2650093.494,1125093.24,698.69999998188],
                [2650101.04,1125086.6770000001,698.716000014305],
                [2650108.585,1125080.115,698.735999995232],
                [2650116.13,1125073.5520000001,698.755000048637],
                [2650123.675,1125066.989,698.777000003815],
                [2650131.22,1125060.426,698.79300003624],
                [2650138.765,1125053.863,698.821000033379],
                [2650146.31,1125047.3,698.821000033379],
                [2650153.856,1125040.737,698.852999979019],
                [2650161.401,1125034.175,698.880000048637],
                [2650168.946,1125027.612,698.880000048637],
                [2650176.491,1125021.049,698.880000048637],
                [2650184.036,1125014.486,698.941999965668],
                [2650191.581,1125007.923,698.941999965668],
                [2650199.126,1125001.36,698.997000032425],
                [2650206.671,1124994.797,699.023000055313],
                [2650214.2169999997,1124988.235,699.023000055313],
                [2650221.762,1124981.672,699.070999914169],
                [2650229.307,1124975.1090000002,699.099000030518],
                [2650236.852,1124968.5459999999,699.119999938965],
                [2650244.397,1124961.983,699.142000013351],
                [2650251.942,1124955.42,699.164000087738],
                [2650259.4869999997,1124948.857,699.181999975205],
                [2650267.0319999997,1124942.295,699.181999975205],
                [2650274.578,1124935.732,699.181999975205],
                [2650282.123,1124929.169,699.230999990463],
                [2650289.668,1124922.606,699.248999997139],
                [2650297.213,1124916.043,699.26799999094],
                [2650304.758,1124909.48,699.285999997616],
                [2650312.3030000003,1124902.9170000001,699.304999991417],
                [2650319.848,1124896.355,699.316999985933],
                [2650327.393,1124889.7920000001,699.309999986649],
                [2650334.9390000002,1124883.229,699.355000003338],
                [2650342.484,1124876.666,699.375000014067],
                [2650350.029,1124870.103,699.394000007868],
                [2650357.574,1124863.54,699.417999996901],
                [2650365.119,1124856.977,699.44599999404],
                [2650372.664,1124850.415,699.471],
                [2650380.2090000003,1124843.852,699.494999989033],
                [2650387.754,1124837.289,699.518999978066],
                [2650395.3,1124830.726,699.546999975205],
                [2650402.845,1124824.163,699.574999972343],
                [2650410.39,1124817.6,699.591999991894],
                [2650417.935,1124811.037,699.623000010014],
                [2650425.48,1124804.475,699.640000029564],
                [2650433.025,1124797.912,699.675999983311],
                [2650440.571,1124791.3490000002,699.70500002718],
                [2650448.117,1124784.788,699.73499999857],
                [2650455.663,1124778.227,699.744999989033],
                [2650463.21,1124771.665,699.791999979973],
                [2650470.756,1124765.104,699.817000015736],
                [2650473.2471084353,1124762.9371153533,699.81499998188],
                [2650478.301,1124758.541,699.82899998045],
                [2650480.8125,1124756.35,699.833999975681],
                [2650488.33,1124749.804,699.846],
                [2650495.875,1124743.241,699.869000001907],
                [2650503.421,1124736.678,699.883999987602],
                [2650510.966,1124730.115,699.914000018597],
                [2650518.511,1124723.553,699.935999973774],
                [2650526.056,1124716.99,699.946000023842],
                [2650533.601,1124710.4270000001,699.977999969482],
                [2650541.1459999997,1124703.864,699.997000022888],
                [2650548.691,1124697.301,700.015000029564],
                [2650556.2369999997,1124690.738,700.03300003624],
                [2650563.7819999997,1124684.176,700.053000017166],
                [2650571.327,1124677.613,700.071000023842],
                [2650578.872,1124671.05,700.089000030518],
                [2650586.417,1124664.487,700.105999990463],
                [2650593.962,1124657.924,700.123999997139],
                [2650601.508,1124651.362,700.125000043869],
                [2650609.0530000003,1124644.799,700.15699998951],
                [2650616.598,1124638.236,700.171999975205],
                [2650624.143,1124631.673,700.183000012398],
                [2650624.8816170874,1124631.0305157132,700.183000012398]
              ]
            },
            "properties": {
              "km": 147.170388,
              "id": "35EC",
              "categories": [
                "Axe-KM"
              ],
              "dxfCode": "LWPOLYLINE",
              "layer": "Axe-KM",
              "space": "model",
              "color": "jaune",
              "rotation": 0.0,
              "scale": "1",
              "lineType": "Continuous",
              "lineWeight": "LineWeight040",
              "text": "",
              "blocName": "*Model_Space",
              "xdatas": [
                {
                  "appName": "KM",
                  "data": [
                    {
                      "ctrStr": "{"
                    },
                    {
                      "asciiStr": "KM:147.170388"
                    },
                    {
                      "ctrStr": "}"
                    }
                  ]
                }
              ],
              "attributes": null,
              "dynamics": null
            }
          }
    ]
}