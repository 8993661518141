export default
{ "features":
    [
      {
        "type": "Feature",
        "geometry": {
          "type": "LineString",
          "coordinates": [
            [2650624.8986657253,1124631.015685997,700.1920001068115],
            [2650631.688,1124625.11,700.1970002212524],
            [2650639.233,1124618.547,700.1970002212524],
            [2650646.779,1124611.985,700.239000038147],
            [2650654.324,1124605.422,700.2540003814697],
            [2650661.869,1124598.8590000002,700.2759997406006],
            [2650669.414,1124592.2959999999,700.2960001983643],
            [2650676.9590000003,1124585.733,700.31500025177],
            [2650684.504,1124579.1709999999,700.3270003356934],
            [2650692.049,1124572.608,700.3349997558594],
            [2650699.595,1124566.045,700.3539998092651],
            [2650707.14,1124559.482,700.3650004425049],
            [2650714.685,1124552.919,700.3310000457764],
            [2650722.23,1124546.356,700.3980004348755],
            [2650729.775,1124539.794,700.4109999694824],
            [2650737.32,1124533.231,700.4269997634888],
            [2650744.866,1124526.668,700.4420001068115],
            [2650752.411,1124520.105,700.4549996414185],
            [2650759.956,1124513.5420000001,700.4730002441406],
            [2650767.501,1124506.98,700.4940001525879],
            [2650775.046,1124500.4170000001,700.4660002746582],
            [2650782.591,1124493.854,700.532999710083],
            [2650790.137,1124487.291,700.5560004272461],
            [2650797.682,1124480.728,700.5759999313354],
            [2650805.227,1124474.165,700.6009995498657],
            [2650812.772,1124467.603,700.6240002670288],
            [2650820.317,1124461.04,700.6470000305176],
            [2650827.8619999997,1124454.477,700.6669995346069],
            [2650835.4069999997,1124447.914,700.69000025177],
            [2650842.953,1124441.351,700.7119996109009],
            [2650850.498,1124434.789,700.74299974823],
            [2650858.043,1124428.226,700.7589995422363],
            [2650865.588,1124421.663,700.7870003738403],
            [2650873.133,1124415.1,700.8050000228882],
            [2650880.6780000003,1124408.537,700.8310000457764],
            [2650888.224,1124401.9740000002,700.8609997787476],
            [2650895.769,1124395.412,700.8930003204346],
            [2650903.3140000002,1124388.8490000002,700.9280001678467],
            [2650910.859,1124382.2859999998,700.9660002746582],
            [2650918.404,1124375.723,700.9999997177124],
            [2650925.949,1124369.16,701.0169999160767],
            [2650933.495,1124362.598,701.0410000839233],
            [2650941.04,1124356.035,701.0530001678467],
            [2650948.585,1124349.472,701.0860001602173],
            [2650956.13,1124342.909,701.105000213623],
            [2650963.675,1124336.346,701.1269995727539],
            [2650971.22,1124329.783,701.1470000305176],
            [2650978.765,1124323.221,701.1710001983643],
            [2650986.3109999998,1124316.658,701.1920001068115],
            [2650993.856,1124310.095,701.2090003051758],
            [2651001.401,1124303.5320000001,701.2289998092651],
            [2651008.946,1124296.969,701.2499997177124],
            [2651016.491,1124290.4070000001,701.2689997711182],
            [2651024.036,1124283.844,701.282999710083],
            [2651031.582,1124277.281,701.2910000839233],
            [2651039.127,1124270.7179999999,701.2879998245239],
            [2651046.672,1124264.155,701.2759997406006],
            [2651054.2169999997,1124257.592,701.2569996871948],
            [2651061.762,1124251.03,701.2210003890991],
            [2651069.307,1124244.467,701.1780001678467],
            [2651076.853,1124237.904,701.1269995727539],
            [2651084.398,1124231.341,701.0670001068115],
            [2651091.943,1124224.778,700.9999997177124],
            [2651099.488,1124218.216,700.9369999923706],
            [2651107.0330000003,1124211.653,700.8699996032715],
            [2651114.578,1124205.09,700.8010003128052],
            [2651122.123,1124198.527,700.730000213623],
            [2651129.669,1124191.9640000002,700.6640002288818],
            [2651137.214,1124185.4009999998,700.5980002441406],
            [2651144.759,1124178.8390000002,700.529],
            [2651152.304,1124172.2759999998,700.4610001602173],
            [2651159.849,1124165.713,700.3980004348755],
            [2651167.394,1124159.15,700.3220002212524],
            [2651174.94,1124152.587,700.2549998321533],
            [2651182.485,1124146.024,700.1920001068115],
            [2651190.03,1124139.462,700.11799974823],
            [2651197.575,1124132.899,700.0480000534058],
            [2651205.12,1124126.336,699.9809996643066],
            [2651212.665,1124119.773,699.8849999465942],
            [2651220.211,1124113.21,699.8390004196167],
            [2651227.756,1124106.648,699.7739998855591],
            [2651227.8113058317,1124106.599892356,699.7739998855591],
            [2651235.301,1124100.085,699.7009999313354],
            [2651242.846,1124093.522,699.6330000915527],
            [2651250.391,1124086.959,699.5699998893738],
            [2651257.9359999998,1124080.396,699.5029999771118],
            [2651265.481,1124073.8329999999,699.4360000648498],
            [2651273.027,1124067.271,699.3729998626709],
            [2651280.5719999997,1124060.7079999999,699.279],
            [2651288.117,1124054.145,699.2440001525879],
            [2651295.662,1124047.582,699.1769997634888],
            [2651303.207,1124041.019,699.1099998512268],
            [2651310.752,1124034.457,699.0559999504089],
            [2651318.298,1124027.894,698.9810001411438],
            [2651325.8430000003,1124021.331,698.9119998970032],
            [2651333.3880000003,1124014.768,698.845999912262],
            [2651340.933,1124008.205,698.845999912262],
            [2651348.478,1124001.642,698.7099997406006],
            [2651356.023,1123995.08,698.631999671936],
            [2651363.569,1123988.517,698.631999671936],
            [2651371.114,1123981.9540000001,698.631999671936],
            [2651378.659,1123975.3909999998,698.4249998931884],
            [2651386.204,1123968.828,698.3600003128051],
            [2651393.749,1123962.2659999998,698.292999923706],
            [2651401.294,1123955.703,698.2459999923706],
            [2651408.839,1123949.14,698.1529995803833],
            [2651416.385,1123942.577,698.0230004196167],
            [2651423.93,1123936.014,698.0010001068115],
            [2651431.475,1123929.451,697.9300000076294],
            [2651439.02,1123922.889,697.8609997634887],
            [2651446.565,1123916.326,697.8609997634887],
            [2651454.11,1123909.763,697.7280003433227],
            [2651461.656,1123903.2,697.6539999847412],
            [2651469.201,1123896.637,697.591999710083],
            [2651476.746,1123890.075,697.591999710083],
            [2651484.2909999997,1123883.512,697.4560000305175],
            [2651491.836,1123876.949,697.381999671936],
            [2651499.381,1123870.386,697.381999671936],
            [2651506.9269999997,1123863.8229999999,697.381999671936],
            [2651514.472,1123857.26,697.17699974823],
            [2651522.017,1123850.6979999999,697.17699974823],
            [2651529.562,1123844.135,697.0670000915527],
            [2651537.107,1123837.572,696.957999885559],
            [2651544.652,1123831.009,696.8589999084472],
            [2651552.1969999997,1123824.446,696.8589999084472],
            [2651559.7430000002,1123817.884,696.7309996490478],
            [2651567.288,1123811.321,696.6740004425048],
            [2651574.833,1123804.758,696.6430003051757],
            [2651582.378,1123798.195,696.5720002059936],
            [2651589.923,1123791.632,696.3970000152588],
            [2651597.4680000003,1123785.0690000001,696.3930003051757],
            [2651605.014,1123778.507,696.3179995422363],
            [2651612.559,1123771.9440000001,696.2759997253418],
            [2651620.1040000003,1123765.381,696.1690003280639],
            [2651635.194,1123752.255,696.1690003280639],
            [2651642.739,1123745.693,695.9509999160766],
            [2651650.284,1123739.13,695.9109999542236],
            [2651657.83,1123732.567,695.829000175476],
            [2651665.375,1123726.004,695.7330004577636],
            [2651672.92,1123719.4409999999,695.7330004577636],
            [2651680.465,1123712.878,695.7330004577636],
            [2651688.01,1123706.3159999999,695.5080000762939],
            [2651695.555,1123699.753,695.5080000762939],
            [2651703.101,1123693.19,695.377999961853],
            [2651710.6459999997,1123686.627,695.377999961853],
            [2651718.191,1123680.064,695.2049996261596],
            [2651725.736,1123673.502,695.131999671936],
            [2651733.281,1123666.939,695.0820004348755],
            [2651740.826,1123660.376,694.9289996032714],
            [2651748.372,1123653.813,694.9090000991821],
            [2651755.917,1123647.25,694.8830000762939],
            [2651763.462,1123640.687,694.7639996414184],
            [2651771.0069999998,1123634.125,694.7289997940063],
            [2651778.5519999997,1123627.562,694.7289997940063],
            [2651786.097,1123620.999,694.7289997940063],
            [2651793.642,1123614.436,694.5150000457763],
            [2651801.188,1123607.873,694.414000213623],
            [2651808.733,1123601.31,694.3500000839233],
            [2651816.278,1123594.748,694.3500000839233],
            [2651823.8230000003,1123588.185,694.211999595642],
            [2651831.3680000002,1123581.622,694.211999595642],
            [2651838.913,1123575.0590000001,694.0989996795654],
            [2651846.4590000003,1123568.496,693.9999997024536],
            [2651854.004,1123561.9340000001,693.957999885559],
            [2651861.549,1123555.371,693.8650004272461],
            [2651869.094,1123548.808,693.7980000381469],
            [2651876.639,1123542.245,693.7980000381469],
            [2651891.73,1123529.119,693.7980000381469],
            [2651899.275,1123522.557,693.7980000381469],
            [2651906.82,1123515.994,693.7980000381469],
            [2651921.91,1123502.868,693.7980000381469],
            [2651929.455,1123496.305,693.7980000381469],
            [2651937,1123489.743,692.7409998779297],
            [2651944.546,1123483.18,692.7409998779297],
            [2651952.091,1123476.617,692.7409998779297],
            [2651959.636,1123470.054,692.7409998779297],
            [2651967.181,1123463.491,692.7409998779297],
            [2651974.726,1123456.928,692.7409998779297],
            [2651982.2709999997,1123450.366,692.7409998779297],
            [2651982.3332349802,1123450.3118722267,692.7409998779297],
            [2651989.817,1123443.803,692.7409998779297],
            [2651997.3619999997,1123437.24,692.5609995727539],
            [2652004.9069999997,1123430.6770000001,692.5180003051757],
            [2652012.452,1123424.114,692.5180003051757],
            [2652019.997,1123417.5520000001,692.3919999008178],
            [2652035.088,1123404.426,692.2140004043579],
            [2652042.633,1123397.863,692.1470000152588],
            [2652050.1780000003,1123391.3,692.071],
            [2652057.723,1123384.737,692.0119999847412],
            [2652065.268,1123378.175,691.9329999885559],
            [2652072.813,1123371.612,691.8650001487732],
            [2652080.358,1123365.049,691.7969998321533],
            [2652087.904,1123358.486,691.7280000648499],
            [2652095.449,1123351.923,691.6290000877381],
            [2652102.994,1123345.361,691.5859998664856],
            [2652110.539,1123338.798,691.5170000991822],
            [2652118.0840000003,1123332.235,691.4500001869202],
            [2652125.629,1123325.672,691.3749999008179],
            [2652133.175,1123319.1090000002,691.3079999885559],
            [2652140.72,1123312.5459999999,691.2350000343323],
            [2652148.265,1123305.9840000002,691.1620000801087],
            [2652155.81,1123299.4209999999,691.0950001678467],
            [2652163.355,1123292.858,691.0269998512268],
            [2652170.9,1123286.295,690.9570001564026],
            [2652178.446,1123279.732,690.8889998397827],
            [2652185.991,1123273.17,690.8159998855591],
            [2652193.536,1123266.607,690.7299999198914],
            [2652201.081,1123260.044,690.6800002059937],
            [2652208.626,1123253.481,690.5750001869202],
            [2652216.171,1123246.918,690.5420001945496],
            [2652223.716,1123240.355,690.4799999198914],
            [2652231.262,1123233.793,690.4089998207093],
            [2652238.807,1123227.23,690.3400000534058],
            [2652246.352,1123220.6670000001,690.1709999046326],
            [2652253.897,1123214.104,690.177999874115],
            [2652261.442,1123207.541,690.1329997978211],
            [2652268.9869999997,1123200.979,690.0630001029969],
            [2652276.5330000003,1123194.416,689.887999912262],
            [2652284.078,1123187.853,689.9069999656678],
            [2652291.623,1123181.29,689.7579997978211],
            [2652299.168,1123174.727,689.7340001068115],
            [2652306.713,1123168.164,689.6569999656678],
            [2652314.258,1123161.602,689.552999874115],
            [2652321.804,1123155.039,689.5560001335144],
            [2652329.349,1123148.476,689.5269998512268],
            [2652336.894,1123141.913,689.4269999465943],
            [2652344.4390000002,1123135.35,689.3580001792908],
            [2652351.984,1123128.788,689.2889999351502],
            [2652359.529,1123122.225,689.2189997634888],
            [2652367.074,1123115.662,689.1470002136231],
            [2652374.62,1123109.0990000002,689.0750001869202],
            [2652382.165,1123102.5359999998,689.0029999217987],
            [2652389.71,1123095.973,688.9380001029969],
            [2652397.255,1123089.4109999998,688.866000076294],
            [2652404.8,1123082.848,688.8019999465943],
            [2652412.345,1123076.285,688.7329999408722],
            [2652419.891,1123069.722,688.6630000076294],
            [2652427.4359999998,1123063.159,688.5919999084473],
            [2652434.981,1123056.596,688.5229999027252],
            [2652442.526,1123050.034,688.4550000629425],
            [2652450.071,1123043.471,688.3779999217987],
            [2652457.616,1123036.908,688.3110000095368],
            [2652465.162,1123030.345,688.2350000343323],
            [2652472.707,1123023.7820000001,688.1630000076294],
            [2652480.252,1123017.22,688.0940000019074],
            [2652487.797,1123010.6570000001,688.0270000896454],
            [2652495.3419999997,1123004.094,687.9499999485016],
            [2652502.887,1122997.531,687.8839999637604],
            [2652510.432,1122990.9679999999,687.8059998950959],
            [2652517.978,1122984.405,687.7699998817444],
            [2652525.523,1122977.8429999999,687.6750000915528],
            [2652533.068,1122971.28,687.6049999198914],
            [2652540.613,1122964.717,687.5359999141693],
            [2652548.1580000003,1122958.154,687.4669999084473],
            [2652555.703,1122951.591,687.4030000171662],
            [2652563.249,1122945.029,687.3300000629425],
            [2652570.794,1122938.466,687.2580000362397],
            [2652578.339,1122931.903,687.1709999046326],
            [2652585.884,1122925.34,687.1190000972748],
            [2652593.429,1122918.777,687.0510000190735],
            [2652600.974,1122912.2140000002,686.9779999456406],
            [2652608.52,1122905.652,686.9080000123978],
            [2652616.065,1122899.0890000002,686.8440000019074],
            [2652623.61,1122892.5259999998,686.763999958992],
            [2652631.155,1122885.963,686.69499995327],
            [2652638.7,1122879.4,686.6200000247956],
            [2652646.245,1122872.838,686.5460000238419],
            [2652653.79,1122866.275,686.4800000391007],
            [2652661.336,1122859.712,686.406000038147],
            [2652668.881,1122853.149,686.3350000581742],
            [2652676.426,1122846.586,686.2669999799729],
            [2652683.971,1122840.023,686.1999999485016],
            [2652691.516,1122833.461,686.1330000362397],
            [2652699.0609999998,1122826.898,686.0610000095368],
            [2652706.607,1122820.335,685.9900000295639],
            [2652714.152,1122813.772,685.9170000157357],
            [2652721.6969999997,1122807.209,685.8449999890328],
            [2652729.242,1122800.647,685.7769999704361],
            [2652736.787,1122794.084,685.7099999985695],
            [2652744.332,1122787.521,685.634000023365],
            [2652751.878,1122780.9579999999,685.5610000095368],
            [2652759.423,1122774.395,685.4879999957085],
            [2652766.9680000003,1122767.832,685.4209999940396],
            [2652774.5130000003,1122761.27,685.376999994278],
            [2652782.058,1122754.707,685.268999797821],
            [2652789.603,1122748.144,685.158000213623],
            [2652797.148,1122741.581,685.1250002212524],
            [2652804.694,1122735.018,685.0579998321533],
            [2652812.239,1122728.456,684.9830000228882],
            [2652819.784,1122721.893,684.9179999656677],
            [2652827.329,1122715.33,684.8459999389648],
            [2652834.874,1122708.767,684.773999912262],
            [2652842.419,1122702.2040000001,684.7099997825622],
            [2652849.965,1122695.6409999998,684.6390001602173],
            [2652857.51,1122689.0790000001,684.5699999160767],
            [2652865.055,1122682.5159999998,684.4929997749329],
            [2652872.6,1122675.953,684.4069998092651],
            [2652880.145,1122669.39,684.3690001792908],
            [2652887.69,1122662.827,684.2980000801086],
            [2652895.236,1122656.265,684.2279999084473],
            [2652902.781,1122649.702,684.158000213623],
            [2652910.326,1122643.139,684.0959999389648],
            [2652917.871,1122636.576,684.0220000572205],
            [2652925.4159999997,1122630.013,683.9550001449585],
            [2652932.961,1122623.45,683.8840000457764],
            [2652940.506,1122616.888,683.8079998321533],
            [2652948.0519999997,1122610.325,683.7429997749329],
            [2652955.597,1122603.762,683.6710002250671],
            [2652963.142,1122597.199,683.6029999084473],
            [2652970.687,1122590.636,683.5319998092651],
            [2652978.232,1122584.074,683.4589998550415],
            [2652985.777,1122577.511,683.3929998703003],
            [2652993.3230000003,1122570.9479999999,683.3219997711182],
            [2653000.8680000002,1122564.385,683.2330000228882],
            [2653008.413,1122557.822,683.1780001945496],
            [2653015.958,1122551.259,683.0940000839233],
            [2653023.503,1122544.697,683.0379998512268],
            [2653031.048,1122538.134,682.9700000114441],
            [2653038.5930000003,1122531.571,682.8979999847412],
            [2653046.139,1122525.008,682.8560001678467],
            [2653053.684,1122518.445,682.7590000457764],
            [2653061.2290000003,1122511.882,682.6870000190735],
            [2653068.774,1122505.32,682.6180000133514],
            [2653076.319,1122498.757,682.5520000286102],
            [2653083.864,1122492.1940000001,682.4760000534058],
            [2653091.41,1122485.631,682.4030000991821],
            [2653098.955,1122479.068,682.3199999160767],
            [2653106.5,1122472.506,682.262999994278],
            [2653114.045,1122465.943,682.1949999160767],
            [2653121.59,1122459.38,682.1249999828339],
            [2653129.135,1122452.817,682.0569999046326],
            [2653136.681,1122446.254,681.9830000228882],
            [2653144.226,1122439.6909999999,681.9160001106262],
            [2653151.7709999997,1122433.129,681.8580000228882],
            [2653159.316,1122426.5659999999,681.7790000267029],
            [2653166.861,1122420.003,681.7109999485016],
            [2653174.406,1122413.44,681.6159999198914],
            [2653181.951,1122406.877,681.5810000724792],
            [2653189.497,1122400.315,681.512999994278],
            [2653197.042,1122393.752,681.4419998950958],
            [2653204.587,1122387.189,681.3770000762939],
            [2653212.1319999998,1122380.626,681.302999956131],
            [2653219.6769999997,1122374.063,681.2339999504089],
            [2653227.222,1122367.5,681.1660001106262],
            [2653234.768,1122360.938,681.0910000629425],
            [2653242.313,1122354.375,681.0269999332428],
            [2653249.858,1122347.812,680.9509999580383],
            [2653257.403,1122341.249,680.8840000457764],
            [2653264.9480000003,1122334.686,680.8150000400543],
            [2653272.4930000002,1122328.124,680.7350001163483],
            [2653280.039,1122321.561,680.6800000495911],
            [2653287.5840000003,1122314.998,680.6060000486374],
            [2653295.129,1122308.435,680.5379999704361],
            [2653302.674,1122301.872,680.4669999904633],
            [2653310.219,1122295.3090000001,680.3970000572205],
            [2653317.764,1122288.747,680.3250000305176],
            [2653325.309,1122282.1840000001,680.2590000457764],
            [2653332.855,1122275.621,680.1829999513626],
            [2653340.4,1122269.058,680.1180000133514],
            [2653347.945,1122262.495,680.0520000286102],
            [2653355.49,1122255.933,679.9760000534058],
            [2653363.035,1122249.37,679.9109999961853],
            [2653370.58,1122242.807,679.8409999437332],
            [2653378.126,1122236.244,679.7729999847412],
            [2653385.671,1122229.6809999999,679.7050000257492],
            [2653393.216,1122223.118,679.6389999814033],
            [2653400.761,1122216.5559999999,679.5739999837875],
            [2653408.306,1122209.993,679.5049999780655],
            [2653415.851,1122203.43,679.4370000190735],
            [2653423.397,1122196.867,679.3699999876022],
            [2653430.942,1122190.304,679.2999999947548],
            [2653438.4869999997,1122183.742,679.234999997139],
            [2653446.0319999997,1122177.179,679.16799999547],
            [2653453.577,1122170.616,679.1000000066757],
            [2653461.122,1122164.053,679.0359999961853],
            [2653468.667,1122157.49,678.9700000114441],
            [2653476.213,1122150.9270000001,678.9019999928474],
            [2653483.758,1122144.365,678.8340001068116],
            [2653491.3030000003,1122137.8020000001,678.764999862671],
            [2653498.848,1122131.239,678.7309999427796],
            [2653506.393,1122124.676,678.6269998512269],
            [2653513.938,1122118.113,678.5650000534058],
            [2653521.484,1122111.551,678.4999999961854],
            [2653529.029,1122104.988,678.426000114441],
            [2653536.574,1122098.425,678.3600001296998],
            [2653544.119,1122091.862,678.2820000610352],
            [2653551.664,1122085.299,678.2100000343323],
            [2653559.2090000003,1122078.736,678.1380000076294],
            [2653566.755,1122072.174,678.0710000953675],
            [2653574.3,1122065.611,678.0019998512269],
            [2653581.845,1122059.048,677.9349999389649],
            [2653589.39,1122052.485,677.8769998512269],
            [2653596.935,1122045.922,677.804999824524],
            [2653604.48,1122039.36,677.734000202179],
            [2653612.025,1122032.797,677.6609997711182],
            [2653619.571,1122026.2340000002,677.5580000839234],
            [2653627.116,1122019.6709999999,677.5170001945496],
            [2653634.661,1122013.108,677.4400000534058],
            [2653642.206,1122006.545,677.3720002136231],
            [2653649.751,1121999.983,677.3060002288819],
            [2653657.296,1121993.42,677.2329997978211],
            [2653664.8419999997,1121986.857,677.1220002136231],
            [2653672.387,1121980.294,677.0980000457764],
            [2653679.932,1121973.731,677.0320000610352],
            [2653687.477,1121967.168,676.966000076294],
            [2653695.022,1121960.606,676.8969998321534],
            [2653702.567,1121954.043,676.8289999923707],
            [2653710.113,1121947.48,676.7589998207093],
            [2653717.6580000003,1121940.9170000001,676.6729998550416],
            [2653725.203,1121934.354,676.6160001716614],
            [2653732.748,1121927.7920000001,676.4539999923707],
            [2653740.293,1121921.229,676.4749999008179],
            [2653747.838,1121914.666,676.4019999465943],
            [2653755.383,1121908.103,676.3200001678467],
            [2653762.929,1121901.54,676.2630000076294],
            [2653770.474,1121894.977,676.1960000953675],
            [2653778.019,1121888.415,676.0229999980927],
            [2653785.5640000002,1121881.852,676.0520000419617],
            [2653793.109,1121875.289,675.9830000362397],
            [2653800.654,1121868.726,675.9140000305176],
            [2653808.2,1121862.163,675.8459999523163],
            [2653815.745,1121855.601,675.7790000400544],
            [2653823.29,1121849.038,675.7060000858307],
            [2653830.835,1121842.475,675.6380000076294],
            [2653838.38,1121835.912,675.5789999923707],
            [2653845.925,1121829.3490000002,675.4969999752045],
            [2653853.471,1121822.7859999998,675.4270000419617],
            [2653861.016,1121816.2240000002,675.362999912262],
            [2653868.5609999998,1121809.6609999998,675.2909998855591],
            [2653876.106,1121803.098,675.2279999217988],
            [2653883.651,1121796.535,675.1530001125336],
            [2653891.196,1121789.972,675.0850000343323],
            [2653898.741,1121783.41,675.0139999351502],
            [2653906.287,1121776.847,674.9490001163483],
            [2653913.832,1121770.284,674.8770000896454],
            [2653921.377,1121763.721,674.8069999179841],
            [2653928.922,1121757.158,674.7119998893738],
            [2653936.4669999997,1121750.595,674.6659998855591],
            [2653944.012,1121744.033,674.5959999523163],
            [2653951.558,1121737.47,674.522000070572],
            [2653959.103,1121730.9070000001,674.4490001163483],
            [2653966.648,1121724.344,674.3849999866486],
            [2653974.193,1121717.781,674.3129999599457],
            [2653981.738,1121711.219,674.2430000267029],
            [2653989.2830000003,1121704.656,674.1059999427796],
            [2653996.829,1121698.0929999999,674.1050000152588],
            [2654004.374,1121691.53,674.0290000400544],
            [2654011.919,1121684.967,673.9659999570847],
            [2654019.464,1121678.404,673.8969999513627],
            [2654027.009,1121671.842,673.8259999713898],
            [2654034.554,1121665.279,673.7580000123978],
            [2654042.099,1121658.716,673.6809999904633],
            [2654049.645,1121652.153,673.6130000314713],
            [2654057.19,1121645.59,673.5410000047684],
            [2654064.735,1121639.028,673.465000029564],
            [2654072.28,1121632.465,673.3930000028611],
            [2654079.825,1121625.902,673.3200000486374],
            [2654087.37,1121619.3390000002,673.2530000171662],
            [2654094.9159999997,1121612.7759999998,673.1749999485016],
            [2654102.461,1121606.213,673.1080000362397],
            [2654110.006,1121599.6509999998,673.0379999837876],
            [2654117.551,1121593.088,672.9179999790192],
            [2654125.096,1121586.525,672.9010000190735],
            [2654132.641,1121579.962,672.823999997139],
            [2654140.187,1121573.399,672.7540000042916],
            [2654147.732,1121566.837,672.6849999985695],
            [2654155.277,1121560.274,672.6100000104905],
            [2654162.8219999997,1121553.711,672.5369999966622],
            [2654170.367,1121547.148,672.4690000078679],
            [2654177.912,1121540.585,672.3989999852181],
            [2654185.457,1121534.022,672.3320000133515],
            [2654193.003,1121527.46,672.2579999974967],
            [2654200.548,1121520.897,672.1880001029969],
            [2654208.0930000003,1121514.334,672.1230000457764],
            [2654215.6380000003,1121507.771,672.0469998321533],
            [2654223.183,1121501.2079999999,671.985999961853],
            [2654230.728,1121494.646,671.9120000801087],
            [2654238.274,1121488.0829999999,671.839000125885],
            [2654245.819,1121481.52,671.7639998397827],
            [2654245.8983668503,1121481.4509629374,671.7639998397827],
            [2654253.364,1121474.957,671.6930002174378],
            [2654260.909,1121468.394,671.6300000152588],
            [2654268.454,1121461.831,671.5550002059937],
            [2654275.999,1121455.269,671.4840001068115],
            [2654283.545,1121448.706,671.4110001525879],
            [2654291.09,1121442.143,671.3400000534058],
            [2654298.635,1121435.58,671.2730001411438],
            [2654306.18,1121429.017,671.2020000419617],
            [2654313.725,1121422.4540000001,671.1300000152588],
            [2654321.27,1121415.892,671.0620001754761],
            [2654328.815,1121409.3290000001,670.9939998588562],
            [2654336.361,1121402.7659999998,670.927999874115],
            [2654343.906,1121396.203,670.8590001068115],
            [2654351.451,1121389.64,670.7839998207093],
            [2654358.996,1121383.078,670.7150000534058],
            [2654366.5409999997,1121376.515,670.6420000991822],
            [2654374.086,1121369.952,670.5469998321533],
            [2654381.6319999998,1121363.389,670.5030001602173],
            [2654389.1769999997,1121356.826,670.4310001335144],
            [2654396.722,1121350.263,670.3590001068115],
            [2654404.267,1121343.701,670.2819999656678],
            [2654411.812,1121337.138,670.2109998664856],
            [2654419.357,1121330.575,670.1329997978211],
            [2654426.902,1121324.012,670.0570000610352],
            [2654434.4480000003,1121317.449,669.9939998588562],
            [2654441.9930000002,1121310.887,669.9260000190735],
            [2654449.538,1121304.324,669.8580001792908],
            [2654457.083,1121297.761,669.7959999046326],
            [2654464.628,1121291.1979999999,669.7260002098084],
            [2654472.173,1121284.635,669.6630000076294],
            [2654479.719,1121278.072,669.579999824524],
            [2654487.264,1121271.51,669.5030001602173],
            [2654494.809,1121264.947,669.4570001564026],
            [2654502.3540000003,1121258.384,669.387999912262],
            [2654509.899,1121251.821,669.3250001869202],
            [2654517.444,1121245.258,669.2499999008179],
            [2654524.99,1121238.696,669.1860000095368],
            [2654532.535,1121232.133,669.1190000972748],
            [2654540.08,1121225.57,669.0410000286103],
            [2654547.625,1121219.007,668.9690000019074],
            [2654555.17,1121212.4440000001,668.8939999542237],
            [2654562.715,1121205.881,668.8149999580384],
            [2654570.26,1121199.3190000001,668.7440000972748],
            [2654577.806,1121192.756,668.666999956131],
            [2654585.351,1121186.193,668.5949999294281],
            [2654592.8959999997,1121179.63,668.5280000171662],
            [2654600.441,1121173.067,668.4550000629425],
            [2654607.986,1121166.505,668.3849998912812],
            [2654615.531,1121159.942,668.3149999580384],
            [2654623.077,1121153.379,668.2480000457764],
            [2654630.622,1121146.8159999999,668.1799999675751],
            [2654638.167,1121140.253,668.0990001163483],
            [2654645.712,1121133.69,668.0340000591278],
            [2654653.2569999998,1121127.128,667.962000032425],
            [2654660.8019999997,1121120.565,667.887999912262],
            [2654668.348,1121114.002,667.8159998855591],
            [2654675.893,1121107.439,667.741000076294],
            [2654683.438,1121100.876,667.6660000286103],
            [2654690.983,1121094.314,667.5949999294281],
            [2654698.528,1121087.751,667.5240000686646],
            [2654706.0730000003,1121081.188,667.446],
            [2654713.6180000002,1121074.625,667.3749999008179],
            [2654721.164,1121068.062,667.3069999418259],
            [2654728.7090000003,1121061.499,667.2339999876023],
            [2654736.254,1121054.937,667.1619999608994],
            [2654743.799,1121048.374,667.0940000019074],
            [2654751.344,1121041.811,667.0260000429154],
            [2654758.889,1121035.248,666.9600000581742],
            [2654766.435,1121028.685,666.8830000362397],
            [2654773.98,1121022.123,666.8120000562668],
            [2654781.525,1121015.56,666.7430000505448],
            [2654789.07,1121008.997,666.6729999980927],
            [2654796.615,1121002.4340000001,666.6059999666214],
            [2654804.16,1120995.871,666.5360000333786],
            [2654811.706,1120989.308,666.418000002861],
            [2654819.251,1120982.746,666.3869999847412],
            [2654826.796,1120976.183,666.3539999923706],
            [2654834.341,1120969.62,666.2400000295639],
            [2654841.886,1120963.057,666.1710000238419],
            [2654849.431,1120956.494,666.0999999842644],
            [2654856.976,1120949.932,666.0290000042916],
            [2654864.522,1120943.369,665.9540000162125],
            [2654872.067,1120936.8059999999,665.8800000152588],
            [2654879.6119999997,1120930.243,665.8110000095368],
            [2654887.1569999997,1120923.68,665.7440000078678],
            [2654894.702,1120917.117,665.707000007391],
            [2654902.247,1120910.555,665.6140000126362],
            [2654909.793,1120903.992,665.5329999974966],
            [2654917.338,1120897.429,665.4279998321533],
            [2654924.883,1120890.866,665.3460000534058],
            [2654932.4280000003,1120884.303,665.3159998435974],
            [2654939.973,1120877.74,665.2480000038147],
            [2654947.518,1120871.178,665.1559999961853],
            [2654955.0640000002,1120864.615,665.098999835968],
            [2654962.609,1120858.0520000001,665.0240000267029],
            [2654970.154,1120851.489,665.0090001602173],
            [2654977.699,1120844.926,664.8949998397827],
            [2654985.244,1120838.364,664.8289998550415],
            [2654992.789,1120831.801,664.7580002326965],
            [2655000.3340000003,1120825.238,664.6480000991821],
            [2655007.88,1120818.675,664.6189998168945],
            [2655015.425,1120812.112,664.5500000495911],
            [2655022.97,1120805.549,664.4760001678467],
            [2655030.515,1120798.987,664.4079998512268],
            [2655038.06,1120792.424,664.3419998664856],
            [2655045.605,1120785.861,664.263999797821],
            [2655053.151,1120779.298,664.2010000724792],
            [2655060.696,1120772.735,664.1340001602173],
            [2655068.241,1120766.173,664.0610002059937],
            [2655075.786,1120759.61,663.9840000648499],
            [2655083.331,1120753.047,663.9190000076294],
            [2655090.876,1120746.4840000002,663.8460000534058],
            [2655098.422,1120739.9209999999,663.778000213623],
            [2655105.9669999997,1120733.358,663.7060001869202],
            [2655113.512,1120726.7959999999,663.5440000076294],
            [2655121.057,1120720.233,663.5599998016357],
            [2655128.602,1120713.67,663.4780000228882],
            [2655136.147,1120707.107,663.4079998512268],
            [2655143.692,1120700.544,663.3339999694824],
            [2655151.238,1120693.982,663.2590001602173],
            [2655158.7830000003,1120687.419,663.1919997711182],
            [2655166.328,1120680.856,663.1249998588562],
            [2655173.873,1120674.293,663.0500000495911],
            [2655181.418,1120667.73,662.9789999504089],
            [2655188.963,1120661.1670000001,662.9129999656677],
            [2655196.509,1120654.605,662.8440001983643],
            [2655204.054,1120648.0420000001,662.7759998817444],
            [2655211.599,1120641.479,662.7060001869202],
            [2655219.144,1120634.916,662.6429999847412],
            [2655226.6890000002,1120628.353,662.5909999389648],
            [2655234.234,1120621.791,662.4959999103546],
            [2655241.78,1120615.228,662.422999956131],
            [2655249.325,1120608.665,662.3509999294281],
            [2655256.87,1120602.102,662.2719999332428],
            [2655264.415,1120595.539,662.2010000724792],
            [2655271.96,1120588.976,662.1280001182556],
            [2655279.505,1120582.414,662.0600000400543],
            [2655287.05,1120575.851,661.9910000343323],
            [2655294.596,1120569.288,661.9129999656677],
            [2655302.141,1120562.725,661.8460000534058],
            [2655309.6859999998,1120556.162,661.7770000476837],
            [2655317.231,1120549.6,661.7050000209808],
            [2655324.776,1120543.037,661.6009999294281],
            [2655332.321,1120536.4740000002,661.5909999389648],
            [2655339.867,1120529.9109999998,661.4959999103546],
            [2655347.412,1120523.348,661.4499999065399],
            [2655354.957,1120516.785,661.3539999504089],
            [2655362.502,1120510.223,661.2860001106262],
            [2655370.047,1120503.66,661.2150000114441],
            [2655377.5919999997,1120497.097,661.143999912262],
            [2655385.1380000003,1120490.534,661.077],
            [2655392.683,1120483.971,661.0040000457764],
            [2655400.228,1120477.409,660.9350000400543],
            [2655407.773,1120470.846,660.8650001068115],
            [2655415.318,1120464.283,660.7970000286102],
            [2655422.863,1120457.72,660.7189999599457],
            [2655430.4080000003,1120451.1570000001,660.6339999217987],
            [2655437.954,1120444.594,660.5570000190735],
            [2655445.499,1120438.0320000001,660.4830000181198],
            [2655453.044,1120431.469,660.3899999637604],
            [2655460.589,1120424.906,660.3209999580383],
            [2655468.134,1120418.3429999999,660.2480000038147],
            [2655475.679,1120411.78,660.1609999914169],
            [2655483.225,1120405.2179999999,660.0800000209808],
            [2655490.77,1120398.655,660.0040000457764],
            [2655498.315,1120392.092,659.9270000238419],
            [2655505.86,1120385.529,659.856000043869],
            [2655513.405,1120378.966,659.787000038147],
            [2655520.95,1120372.403,659.7189999599457],
            [2655528.496,1120365.841,659.6490000267029],
            [2655536.0409999997,1120359.278,659.577],
            [2655543.586,1120352.715,659.5089999814033],
            [2655551.131,1120346.152,659.4420000095367],
            [2655558.676,1120339.5890000002,659.3789999861717],
            [2655566.221,1120333.0259999998,659.3070000190735],
            [2655573.766,1120326.4640000002,659.2399999876022],
            [2655581.312,1120319.9009999998,659.1759999771118],
            [2655588.857,1120313.338,659.1059999842644],
            [2655596.402,1120306.775,659.03799999547],
            [2655603.9469999997,1120300.212,658.9709999938011],
            [2655611.492,1120293.65,658.9400000054836],
            [2655619.037,1120287.087,658.8360000033379],
            [2655626.583,1120280.524,658.8010000069141],
            [2655649.2180000003,1120260.835,658.8010000069141],
            [2655656.7630000003,1120254.273,658.8010000069141],
            [2655664.308,1120247.71,658.8010000069141],
            [2655671.8540000003,1120241.147,658.3730001029968],
            [2655679.399,1120234.584,658.3049997863769],
            [2655686.944,1120228.021,658.2350000915527],
            [2655694.489,1120221.459,658.1589998779297],
            [2655702.034,1120214.896,658.0900001106262],
            [2655709.579,1120208.3329999999,658.0190000114441],
            [2655717.124,1120201.77,657.9499997673034],
            [2655724.67,1120195.207,657.8780002174377],
            [2655732.215,1120188.644,657.8070001182556],
            [2655739.76,1120182.082,657.7829999504089],
            [2655747.305,1120175.519,657.3270000991821],
            [2655754.85,1120168.956,657.3270000991821],
            [2655762.395,1120162.393,657.3270000991821],
            [2655769.941,1120155.83,657.3270000991821],
            [2655777.486,1120149.268,657.3270000991821],
            [2655785.031,1120142.705,657.3270000991821],
            [2655792.576,1120136.142,657.250999885559],
            [2655800.121,1120129.5790000001,657.1789998588562],
            [2655815.211,1120116.453,657.0419997749328],
            [2655822.7569999998,1120109.8909999998,656.9720000801086],
            [2655837.847,1120096.765,656.8369998512268],
            [2655845.392,1120090.202,656.7649998245239],
            [2655852.937,1120083.639,656.7649998245239],
            [2655860.482,1120077.077,656.7649998245239],
            [2655868.028,1120070.514,656.7649998245239],
            [2655875.5730000003,1120063.951,656.4959997711181],
            [2655883.1180000002,1120057.388,656.4330000457763],
            [2655890.663,1120050.825,656.3610000190735],
            [2655898.208,1120044.262,656.2979998168945],
            [2655905.753,1120037.7,656.2309999046325],
            [2655913.299,1120031.137,656.1610002098083],
            [2655920.844,1120024.574,656.0919999656677],
            [2655928.389,1120018.011,656.0919999656677],
            [2655943.4790000003,1120004.886,655.5369998989105],
            [2655951.024,1119998.3229999999,655.5369998989105],
            [2655958.569,1119991.76,655.5369998989105],
            [2655966.115,1119985.197,655.5369998989105],
            [2655973.66,1119978.634,655.5369998989105],
            [2655981.205,1119972.071,655.5369998989105],
            [2655988.75,1119965.509,655.4690000591278],
            [2656003.84,1119952.383,655.3280000267029],
            [2656011.386,1119945.82,655.2590000209808],
            [2656018.931,1119939.257,655.2590000209808],
            [2656026.476,1119932.695,655.1240000305175],
            [2656034.0209999997,1119926.132,655.0559999523163],
            [2656041.566,1119919.5690000001,655.0559999523163],
            [2656049.111,1119913.006,654.9139999923706],
            [2656056.6569999997,1119906.443,654.9139999923706],
            [2656064.202,1119899.88,654.7759999809265],
            [2656071.747,1119893.318,654.7009999332428],
            [2656079.292,1119886.755,654.6370000419616],
            [2656086.837,1119880.192,654.5710000572204],
            [2656094.3819999998,1119873.629,654.5259999809265],
            [2656101.9269999997,1119867.0659999999,654.4520000991821],
            [2656109.473,1119860.504,654.4520000991821],
            [2656117.018,1119853.9409999999,654.4520000991821],
            [2656124.563,1119847.378,654.2209999141693],
            [2656132.108,1119840.815,654.2209999141693],
            [2656139.653,1119834.252,654.0799998817444],
            [2656147.1980000003,1119827.689,654.000999885559],
            [2656154.744,1119821.127,653.9330000457763],
            [2656162.289,1119814.564,653.9330000457763],
            [2656169.8340000003,1119808.001,653.9330000457763],
            [2656184.924,1119794.875,653.6440000114441],
            [2656192.469,1119788.312,653.6440000114441],
            [2656200.015,1119781.75,653.5010000047683],
            [2656207.56,1119775.187,653.438000041008],
            [2656215.105,1119768.624,653.438000041008],
            [2656222.65,1119762.061,653.2939999876022],
            [2656230.195,1119755.498,653.229000049591],
            [2656237.74,1119748.936,653.1559999761581],
            [2656245.285,1119742.373,653.1559999761581],
            [2656252.831,1119735.81,653.0270000276565],
            [2656260.376,1119729.247,652.948999958992],
            [2656275.466,1119716.121,652.8129999814033],
            [2656283.011,1119709.5590000001,652.8129999814033],
            [2656290.556,1119702.996,652.8129999814033],
            [2656298.102,1119696.433,652.6049999771118],
            [2656313.192,1119683.307,652.6049999771118],
            [2656320.7369999997,1119676.745,652.4030000147819],
            [2656328.2819999997,1119670.182,652.4030000147819],
            [2656335.827,1119663.619,652.4030000147819],
            [2656343.373,1119657.0559999999,652.1959999976158],
            [2656350.918,1119650.493,652.1959999976158],
            [2656358.463,1119643.93,652.0539999929666],
            [2656366.008,1119637.368,651.9850001296998],
            [2656373.5530000003,1119630.805,651.9169998130799],
            [2656381.098,1119624.242,651.8359999618531],
            [2656388.643,1119617.679,651.7699999771119],
            [2656396.1890000002,1119611.116,651.6929998359681],
            [2656403.734,1119604.554,651.5789999923707],
            [2656411.279,1119597.991,651.5520000419617],
            [2656418.824,1119591.428,651.4229998550416],
            [2656426.369,1119584.865,651.4140000305176],
            [2656433.914,1119578.3020000001,651.3350000343323],
            [2656441.46,1119571.739,651.2930002174378],
            [2656449.005,1119565.1770000001,651.1950001678467],
            [2656456.55,1119558.614,651.1230001411438],
            [2656464.095,1119552.051,651.0520000419617],
            [2656471.64,1119545.488,650.9780001602173],
            [2656479.185,1119538.925,650.9089999160767],
            [2656486.731,1119532.363,650.8420000038147],
            [2656494.276,1119525.8,650.7760000190735],
            [2656501.821,1119519.237,650.7020001373292],
            [2656509.366,1119512.674,650.6290001831055],
            [2656509.4639758253,1119512.5887759652,650.6290001831055],
            [2656516.911,1119506.111,650.551000114441],
            [2656524.456,1119499.548,650.4829997978211],
            [2656532.001,1119492.986,650.3850002250672],
            [2656539.547,1119486.423,650.3249998054505],
            [2656547.0919999997,1119479.86,650.2529997787476],
            [2656554.637,1119473.297,650.1960000953675],
            [2656562.182,1119466.7340000002,650.1230001411438],
            [2656569.727,1119460.172,650.0529999694825],
            [2656577.272,1119453.6090000002,649.9829997978211],
            [2656584.818,1119447.0459999999,649.8540000877381],
            [2656592.363,1119440.483,649.8420000038147],
            [2656599.9080000003,1119433.92,649.6919999084473],
            [2656607.453,1119427.357,649.7010002098084],
            [2656614.998,1119420.795,649.6240000686646],
            [2656622.543,1119414.232,649.5560002288819],
            [2656630.089,1119407.669,649.487999912262],
            [2656637.634,1119401.106,649.4180002174378],
            [2656645.179,1119394.543,649.3489999732972],
            [2656652.724,1119387.981,649.2809998950959],
            [2656660.269,1119381.418,649.2090001068116],
            [2656667.8140000002,1119374.855,649.1370000801087],
            [2656675.359,1119368.2920000001,649.0650000534058],
            [2656682.905,1119361.729,648.9999999961854],
            [2656690.45,1119355.166,648.9130001029969],
            [2656697.995,1119348.604,648.8780000171662],
            [2656705.54,1119342.041,648.7809998950959],
            [2656713.085,1119335.478,648.7119998893738],
            [2656720.63,1119328.915,648.641999956131],
            [2656728.176,1119322.352,648.5720000228882],
            [2656735.721,1119315.79,648.5050001106263],
            [2656743.266,1119309.227,648.4330000839234],
            [2656750.8109999998,1119302.664,648.3680000267029],
            [2656758.356,1119296.101,648.2839999160767],
            [2656765.901,1119289.538,648.2049999198914],
            [2656773.4469999997,1119282.975,648.1440000495911],
            [2656780.992,1119276.413,648.0769998989106],
            [2656788.537,1119269.85,648.0120000801087],
            [2656796.082,1119263.287,647.9419999084473],
            [2656803.627,1119256.7240000002,647.8729999027253],
            [2656811.172,1119250.1609999998,647.8069999179841],
            [2656818.7169999997,1119243.5990000002,647.7400000057221],
            [2656826.2630000003,1119237.0359999998,647.6749999485016],
            [2656833.808,1119230.473,647.5950000247956],
            [2656841.353,1119223.91,647.5320000610352],
            [2656848.898,1119217.347,647.4649999103547],
            [2656856.443,1119210.784,647.3929998836518],
            [2656863.988,1119204.222,647.3529999217988],
            [2656871.534,1119197.659,647.2539999446869],
            [2656879.079,1119191.096,647.1460000238419],
            [2656886.624,1119184.533,647.113999958992],
            [2656894.169,1119177.97,647.046],
            [2656901.714,1119171.4070000001,646.9830000362397],
            [2656909.259,1119164.845,646.9029999933243],
            [2656916.805,1119158.2820000001,646.8370000085831],
            [2656924.35,1119151.719,646.766999956131],
            [2656931.895,1119145.156,646.6850000581742],
            [2656939.44,1119138.5929999999,646.629999991417],
            [2656946.985,1119132.031,646.5600000581742],
            [2656954.53,1119125.4679999999,646.4880000314713],
            [2656962.075,1119118.905,646.4220000467301],
            [2656969.621,1119112.342,646.3500000200272],
            [2656977.1659999997,1119105.779,646.2869999966622],
            [2656984.711,1119099.216,646.2139999828339],
            [2656992.256,1119092.654,646.1470000109673],
            [2656999.801,1119086.091,646.0820000133515],
            [2657007.346,1119079.528,646.0149999818802],
            [2657014.892,1119072.965,645.9449999890328],
            [2657022.437,1119066.402,645.8749999961854],
            [2657029.982,1119059.84,645.8149999938012],
            [2657037.527,1119053.277,645.7400000057221],
            [2657045.0719999997,1119046.7140000002,645.7050000092984],
            [2657052.617,1119040.1509999998,645.6080000064374],
            [2657060.163,1119033.588,645.5340000054837],
            [2657067.708,1119027.025,645.461999993682],
            [2657075.253,1119020.463,645.3950000534057],
            [2657082.798,1119013.9,645.3400002250671],
            [2657090.3430000003,1119007.337,645.2649999389648],
            [2657097.8880000003,1119000.774,645.2100001106262],
            [2657105.433,1118994.211,645.107999874115],
            [2657112.9790000003,1118987.649,645.0390001068115],
            [2657120.524,1118981.086,644.9709997901916],
            [2657128.069,1118974.523,644.9089999923706],
            [2657135.614,1118967.96,644.8649998435974],
            [2657143.159,1118961.397,644.7669997940063],
            [2657150.704,1118954.834,644.6999998817444],
            [2657158.25,1118948.272,644.6329999694824],
            [2657165.795,1118941.709,644.5289998779297],
            [2657173.34,1118935.146,644.4990001449585],
            [2657180.885,1118928.5829999999,644.4309998283386],
            [2657188.43,1118922.02,644.354000164032],
            [2657195.975,1118915.4579999999,644.2919998893738],
            [2657203.52,1118908.895,644.2199998626709],
            [2657211.066,1118902.332,644.1300001869201],
            [2657218.611,1118895.769,644.0600000152588],
            [2657226.156,1118889.206,644.0050001869201],
            [2657233.701,1118882.643,643.9419999847412],
            [2657241.246,1118876.081,643.876],
            [2657248.7909999997,1118869.518,643.812999797821],
            [2657256.337,1118862.955,643.7409997711181],
            [2657263.8819999998,1118856.392,643.6739998588562],
            [2657271.4269999997,1118849.8290000001,643.6060000190735],
            [2657278.972,1118843.267,643.540999961853],
            [2657286.517,1118836.7040000001,643.4730001220703],
            [2657294.062,1118830.1409999998,643.4039998779297],
            [2657301.608,1118823.578,643.3350001106262],
            [2657309.153,1118817.015,643.2630000839233],
            [2657316.6980000003,1118810.452,643.1830001602173],
            [2657324.2430000002,1118803.89,643.1110001335144],
            [2657331.788,1118797.327,643.0169997940063],
            [2657339.333,1118790.764,642.9670000801086],
            [2657346.878,1118784.201,642.8439999351501],
            [2657354.424,1118777.638,642.8299999961853],
            [2657361.969,1118771.076,642.7550001869201],
            [2657369.514,1118764.513,642.6850000152588],
            [2657377.059,1118757.95,642.615000082016],
            [2657384.6040000003,1118751.387,642.5439999828338],
            [2657392.149,1118744.824,642.471999956131],
            [2657399.695,1118738.261,642.4010000953674],
            [2657407.24,1118731.699,642.3350001106262],
            [2657414.785,1118725.136,642.261999917984],
            [2657422.33,1118718.5729999999,642.1929999122619],
            [2657429.875,1118712.01,642.1269999275207],
            [2657437.42,1118705.447,642.0560000667572],
            [2657444.966,1118698.885,641.984999967575],
            [2657452.511,1118692.322,641.9459999332428],
            [2657460.056,1118685.759,641.8460000286102],
            [2657467.601,1118679.196,641.7730000743866],
            [2657475.1459999997,1118672.633,641.695000005722],
            [2657482.691,1118666.07,641.6299999485016],
            [2657490.236,1118659.508,641.5040000209808],
            [2657497.7819999997,1118652.945,641.4859998950958],
            [2657505.327,1118646.382,641.4189999828338],
            [2657512.872,1118639.8190000001,641.3499999771118],
            [2657520.417,1118633.256,641.2830000648498],
            [2657527.962,1118626.693,641.2389999160766],
            [2657535.5069999998,1118620.131,641.1490000019073],
            [2657543.0530000003,1118613.568,641.0769999752044],
            [2657550.598,1118607.005,641.0079999694824],
            [2657558.143,1118600.442,640.9380000362396],
            [2657565.688,1118593.879,640.8709998855591],
            [2657573.233,1118587.317,640.8049999008178],
            [2657580.778,1118580.754,640.7379999885559],
            [2657588.324,1118574.1909999999,640.6669998893738],
            [2657595.869,1118567.628,640.599000049591],
            [2657603.414,1118561.065,640.5339999923706],
            [2657610.9590000003,1118554.502,640.4589999446869],
            [2657618.504,1118547.94,640.3950000534057],
            [2657626.049,1118541.377,640.3250000009537],
            [2657633.594,1118534.814,640.2599999437332],
            [2657641.14,1118528.251,640.1880000362396],
            [2657648.685,1118521.688,640.1210000047683],
            [2657656.23,1118515.126,640.0459999570846],
            [2657663.775,1118508.563,639.9910000095367],
            [2657671.32,1118502,639.9020000228882],
            [2657678.865,1118495.437,639.8299999961853],
            [2657686.411,1118488.874,639.7549999485016],
            [2657693.956,1118482.311,639.6880000362396],
            [2657701.501,1118475.749,639.6139999756813],
            [2657709.046,1118469.186,639.5429999957084],
            [2657716.591,1118462.623,639.473000002861],
            [2657724.136,1118456.06,639.4009999761581],
            [2657731.682,1118449.497,639.3319999704361],
            [2657739.227,1118442.935,639.2600000033378],
            [2657746.772,1118436.372,639.187999976635],
            [2657754.317,1118429.8090000001,639.1190000007152],
            [2657761.8619999997,1118423.246,639.0519999990463],
            [2657769.4069999997,1118416.683,638.9860000143051],
            [2657776.952,1118410.12,638.9179999957084],
            [2657784.498,1118403.558,638.848000002861],
            [2657792.043,1118396.995,638.7760000059604],
            [2657799.588,1118390.432,638.7059999580383],
            [2657807.133,1118383.869,638.6339999313354],
            [2657814.6780000003,1118377.3059999999,638.5920001144409],
            [2657822.223,1118370.744,638.4940000648498],
            [2657829.769,1118364.1809999999,638.4070001716614],
            [2657837.3140000002,1118357.618,638.3459998245239],
            [2657844.859,1118351.055,638.273999797821],
            [2657852.404,1118344.492,638.2160001869202],
            [2657859.949,1118337.929,638.1330000038147],
            [2657867.494,1118331.367,638.0609999771118],
            [2657875.04,1118324.804,637.9880000228882],
            [2657882.585,1118318.241,637.8830000038147],
            [2657890.13,1118311.678,637.8420001144409],
            [2657897.675,1118305.115,637.773999797821],
            [2657905.22,1118298.553,637.6989999885559],
            [2657912.765,1118291.99,637.6250001068115],
            [2657920.31,1118285.4270000001,637.5569997901916],
            [2657927.856,1118278.864,637.4810000534058],
            [2657935.401,1118272.301,637.4090000267029],
            [2657942.946,1118265.738,637.337],
            [2657950.491,1118259.176,637.2669998283386],
            [2657958.036,1118252.613,637.1830001945495],
            [2657965.581,1118246.05,637.1199999923706],
            [2657973.127,1118239.487,637.0530000801086],
            [2657980.672,1118232.924,636.9810000534058],
            [2657988.2169999997,1118226.362,636.9080000991821],
            [2657995.762,1118219.799,636.837],
            [2658003.307,1118213.236,636.7640000457764],
            [2658010.852,1118206.673,636.6999999160766],
            [2658018.398,1118200.11,636.6209999198913],
            [2658025.943,1118193.547,636.5510002250671],
            [2658033.488,1118186.985,636.480000125885],
            [2658041.0330000003,1118180.422,636.4140001411438],
            [2658048.578,1118173.8590000002,636.3690000648498],
            [2658056.123,1118167.2959999999,636.2690001602173],
            [2658063.668,1118160.733,636.2050000305176],
            [2658071.214,1118154.1709999999,636.1350000972748],
            [2658078.759,1118147.608,636.0700000400543],
            [2658086.304,1118141.045,635.9810000534058],
            [2658093.849,1118134.482,635.9280000801086],
            [2658101.394,1118127.919,635.8639999504089],
            [2658108.9390000002,1118121.356,635.7960001106262],
            [2658116.485,1118114.794,635.7289999599457],
            [2658124.03,1118108.231,635.6590000267029],
            [2658131.575,1118101.668,635.5909999485016],
            [2658139.12,1118095.105,635.5149999732971],
            [2658146.665,1118088.5420000001,635.4499999160766],
            [2658131.575,1118101.668,635.5909999485016],
            [2658139.12,1118095.105,635.5149999732971],
            [2658146.665,1118088.5420000001,635.4459999675751],
            [2658154.21,1118081.979,635.3980001087189],
            [2658161.756,1118075.4170000001,635.3339999790192],
            [2658169.301,1118068.854,635.2359999294281],
            [2658176.846,1118062.291,635.166999923706],
            [2658184.391,1118055.728,635.1000000114441],
            [2658191.9359999998,1118049.165,635.0340000267029],
            [2658199.481,1118042.603,634.9670001144409],
            [2658207.026,1118036.04,634.8899999732971],
            [2658214.5719999997,1118029.477,634.8209999675751],
            [2658222.117,1118022.914,634.7500001068115],
            [2658229.662,1118016.351,634.7020000095367],
            [2658237.207,1118009.788,634.6030000324249],
            [2658244.752,1118003.226,634.5319999332428],
            [2658252.297,1117996.663,634.462],
            [2658259.8430000003,1117990.1,634.3920000667572],
            [2658267.3880000003,1117983.537,634.3270000095367],
            [2658274.933,1117976.9740000002,634.2440000648498],
            [2658282.478,1117970.412,634.1879999513626],
            [2658290.023,1117963.8490000002,634.1130000228882],
            [2658297.568,1117957.2859999998,634.0459999914169],
            [2658305.114,1117950.723,633.9730000371933],
            [2658312.659,1117944.16,633.8969999427795],
            [2658320.204,1117937.597,633.8389999742508],
            [2658327.749,1117931.035,633.7640000457764],
            [2658335.294,1117924.472,633.7000000352859],
            [2658342.839,1117917.909,633.6299999828339],
            [2658350.384,1117911.346,633.5659999723434],
            [2658357.93,1117904.783,633.4949999923706],
            [2658365.475,1117898.221,633.4259999866485],
            [2658373.02,1117891.658,633.3589999551773],
            [2658380.565,1117885.095,633.2840000267029],
            [2658388.11,1117878.5320000001,633.2200000162125],
            [2658395.655,1117871.969,633.1479999895096],
            [2658403.201,1117865.406,633.0680000061989],
            [2658410.746,1117858.844,633.0139999861717],
            [2658418.2909999997,1117852.281,632.9430000061989],
            [2658425.836,1117845.7179999999,632.8740000004768],
            [2658433.381,1117839.155,632.801999973774],
            [2658440.926,1117832.592,632.7380000228882],
            [2658448.472,1117826.03,632.667000013113],
            [2658456.017,1117819.467,632.603999989748],
            [2658463.562,1117812.904,632.575999992609],
            [2658471.107,1117806.341,632.4660000081062],
            [2658478.652,1117799.778,632.3939999963045],
            [2658486.204,1117793.223,632.3079999923706],
            [2658493.777,1117786.692,632.2489999771118],
            [2658501.387,1117780.205,632.1769999504089],
            [2658509.049,1117773.779,632.0770000457763],
            [2658516.779,1117767.435,631.9239999294281],
            [2658524.589,1117761.19,631.8179999828338],
            [2658532.495,1117755.0659999999,631.7840000629425],
            [2658540.5069999998,1117749.0829999999,631.7719999790191],
            [2658548.639,1117743.263,631.6929999828338],
            [2658556.899,1117737.627,631.6159999608993],
            [2658565.297,1117732.199,631.5069999933243],
            [2658573.838,1117726.999,631.3739999771118],
            [2658582.517,1117722.031,631.4169999599457],
            [2658591.326,1117717.3,631.3409999847412],
            [2658600.261,1117712.8090000001,631.2639999628067],
            [2658609.3140000002,1117708.561,631.1799999713897],
            [2658618.475,1117704.554,631.1130000591278],
            [2658627.7369999997,1117700.784,631.0419999599457],
            [2658637.0919999997,1117697.25,630.969000005722],
            [2658646.524,1117693.9309999999,630.8420000314712],
            [2658656.022,1117690.8020000001,630.7789999485016],
            [2658665.5730000003,1117687.84,630.7619999885559],
            [2658675.167,1117685.018,630.6099999785423],
            [2658684.794,1117682.312,630.5610000133514],
            [2658694.446,1117679.696,630.5200000047683],
            [2658704.114,1117677.144,630.4090000033378],
            [2658713.793,1117674.63,630.3269999861717],
            [2658723.475,1117672.127,630.2339999914169],
            [2658733.1569999997,1117669.625,630.1689999938011],
            [2658742.839,1117667.123,630.1919999957084],
            [2658752.5209999997,1117664.621,630.0629999876022],
            [2658762.203,1117662.119,630.0170000135898],
            [2658771.885,1117659.617,629.9740000009537],
            [2658781.567,1117657.115,629.888999992609],
            [2658791.249,1117654.613,629.9],
            [2658800.932,1117652.116,629.7409999981522],
            [2658805.051,1117651.072,629.7939999938011],
            [2658805.7854152718,1117650.890193067,629.7980000019073]
          ]
        },
        "properties": {
          "km": 156.200928,
          "id": "35F1",
          "categories": [
            "Axe-KM"
          ],
          "dxfCode": "LWPOLYLINE",
          "layer": "Axe-KM",
          "space": "model",
          "color": "jaune",
          "rotation": 0.0,
          "scale": "1",
          "lineType": "Continuous",
          "lineWeight": "LineWeight040",
          "text": "",
          "blocName": "*Model_Space",
          "xdatas": [
            {
              "appName": "KM",
              "data": [
                {
                  "ctrStr": "{"
                },
                {
                  "asciiStr": "KM:156"
                },
                {
                  "ctrStr": "}"
                }
              ]
            }
          ],
          "attributes": null,
          "dynamics": null
        }
      }
    ]
}