
import * as THREE from "../../libs/three.js/build/three.module.js";
import {Annotation} from "../Annotation.js";
import env from '../environment';
import simplonFeatures119 from '../simplon-features-119';
import simplonFeatures127 from '../simplon-features-127';
import {Measure} from "../utils/Measure.js";
import {Utils} from "../utils.js";

var featuresSdio = undefined;

export class SdioFetch {
	constructor(viewer) {
		this.viewer = viewer;
		this.measuringTool = viewer.measuringTool;
		this.profileTool = viewer.profileTool;
		this.volumeTool = viewer.volumeTool;
		
	}
	

	static async initLogin(user, pass) {
		
		 let token = await this.login(user, pass);
		 if (token && token.token)  {
		  // Save to local storage
		  let user = await this.getCurrentUser(token.token);
		  SdioState.saveToken(token, user);
		  if (user && user.firstname){
			document.querySelector('[name="status-message"]').innerHTML = 
			`The connection succeeded for ${user.firstname} ! <br> <a style="text-decoration: underline;" href="../">Go to viewer</a>`;
	  
			window.open('/',"_self");
			  
		  } 
		  
		 }
	  
		}
	  
	  
	static async login(username, password) {
		  var token = await this.postToken(username, password);
		  if (token && token.error) {
			alert(token.error);
		  } else {
			return token;
		  }
		  return true;
	}
	  
	static async postToken(user, pass) {

		let apiEndpoint = "/auth/token";
		let body = `{"username":"${user}","password":"${pass}"}`;
	
		if (apiEndpoint && user && pass) {
			let prefix = "?" + 'apiKey=' + env.swissdata.apiKey;
	
			const options = {
				method: 'POST',
				headers: {'Content-Type': 'application/json'},
				body: body
			  };
			  
			// POST Request.
			let data = await fetch(env.swissdata.host + apiEndpoint + prefix, options)
				// Handle success
				.then(response => response.json())  // convert to json
				.then(json => {
					return json;
				})
				.catch(err => {
					console.log('Request Failed', err)
					return undefined;
				}); // Catch errors
				
			return data;

		}
	}

	static async getCurrentUser(token) {

		let apiEndpoint = "/user/current";
	
		if (apiEndpoint && token) {
			let prefix = "?" + 'apiKey=' + env.swissdata.apiKey;
	
			const options = {
			method: 'GET',
			headers: { authorization: 'Bearer ' + token}
			};

			// Get Request.
			let data = await fetch(env.swissdata.host + apiEndpoint + prefix, options)
				// Handle success
				.then(response => response.json())  // convert to json
				.then(json => {
					return json;
				})
				.catch(err => {
					console.log('Request Failed', err)
					return undefined;
				}); // Catch errors
				
			return data;

		}
	}
	
	static async save(apiEndpoint, data, objectId) {
		if (apiEndpoint && data) {
			// if exist in DB => Update
			if (objectId) {
				const prefix = "/" + objectId + "?" + 'apiKey=' + env.swissdata.apiKey;
				let result1 = await fetch(env.swissdata.host + apiEndpoint + prefix, {
					method: "PUT",
					body: JSON.stringify(data),
					headers: { "Content-type": "application/json; charset=UTF-8" }
				})  .then(response => response.json())
				.catch(err => console.log(err));
				
				return result1;
				
			} else {
				// Create new one
				const prefix = "?" + 'apiKey=' + env.swissdata.apiKey;
				let result2 = await fetch(env.swissdata.host + apiEndpoint + prefix, {
					method: "POST",
					body: JSON.stringify(data),
					headers: { "Content-type": "application/json; charset=UTF-8" }
				})  .then(response => response.json())
				.catch(err => console.log(err));
				
				return result2;
			}
		}
	}
	
	static async getAll(apiEndpoint, filter) {

		if (apiEndpoint) {
			let prefix = "?" + 'apiKey=' + env.swissdata.apiKey;
			if (filter) prefix = "?" + filter + '&' +  'apiKey=' + env.swissdata.apiKey;
	
			// GET Request.
			let data = await fetch(env.swissdata.host + apiEndpoint + prefix)
				// Handle success
				.then(response => response.json())  // convert to json
				.then(json => {
					return json;
				})
				.catch(err => {
					console.log('Request Failed', err)
					return undefined;
				}); // Catch errors
				
			return data;

		}
	}

	static async delete(apiEndpoint, id) {
		if (apiEndpoint && id) {
			console.log('Delete : update');
			let _data = {};
			const prefix = "/" + id + "?" + 'apiKey=' + env.swissdata.apiKey;
			fetch(env.swissdata.host + apiEndpoint + prefix, {
				method: "DELETE",
				body: JSON.stringify(_data),
				headers: { "Content-type": "application/json; charset=UTF-8" }
			})
				.then(response => {
					// Remove in Three
					let annotations = viewer.scene.annotations;
					for (const ar of annotations.children) {
						if (ar.id && ar.id === id) {
							viewer.scene.removeAnnotation(ar);
						}
					}
					if (response.statusText != 'No Content') {
						response.json()
					}
				})
				.catch(err => console.log(err));

		}
	}

	static async loadProjects() {
		let result = await SdioFetch.getAll("/dynamicdata/project", "limit=1000&sort=sortindex");
		if (result) {
			return result;
		}
	}

	static async getProjects() {
		let allProjects = await this.loadProjects();
		let projects = [];
		let user = SdioState.getUser();
		if (allProjects && allProjects.length > 0) {
			for (const project of allProjects) {
				if (this.checkProjectAccess(project, user)){
					projects.push(project);
				}
			}
		}
		return projects;
	}
	
	static async loadPointClouds() {
		let result = await SdioFetch.getAll("/dynamicdata/pointcloud");
		if (result) {
			return result;
		}
	}

	static async openProject(projectId) {

		// init login
		let userId = SdioState.getUserId();
		if (userId) {
			let expiresDate = SdioState.getUserExpires();
			let expires = new Date(expiresDate);
			let toDay = new Date();
			if (toDay < expires) {
				console.log('logged in')
			} else {
				console.log('logged out expires')
				SdioState.removelocalStorageSwissdata()
			}
		} else {
			console.log('logged out')
			SdioState.removelocalStorageSwissdata()
		}

		// init project
		if (projectId && projectId != "%PROJID%") {
			let projects = await Potree.SdioFetch.loadProjects();
			let user = SdioState.getUser();
			const project = projects.find(a => a.id === projectId);
			if (project) {
				if (project.tag)  window.projectTag = project.tag;
				
				if (this.checkProjectAccess(project, user)){
					window.currentProject = project;
					SdioState.savelocalStorage(project);
				} else {		
					window.currentProject = undefined;
					SdioState.removelocalStorage();
					window.open('/login/',"_self");
				}
			}
		} else {
			SdioState.removelocalStorage();
		}
	}

	static checkProjectAccess(project, user){
		let permissionOk = false;
		let isPublic = false;
		if (project.public && project.public == true) {
			isPublic = true;
			permissionOk = true;
		}
		if (user && user.id && isPublic == false && project.users && project.users.length > 0) {
			if (user.roles && user.roles.includes('admin')) return true;
			for (const u of project.users) {
				if (u.userId && u.userId == user.id && u.roles && u.roles.length > 0) {
					permissionOk = true;
				}
			}
		}
		return permissionOk;
	}

	static async selectProject(tag, projectName) {
		if (projectName) {
			 window.open('../../projects/' + projectName,"_self");
			 SdioState.setTag(tag);
		}
	}


	static async loadAxisFeatures(projectId) {
		if (projectId){
			let result = undefined;
			if (projectId == '64a1d52f745a10001e5effb1') {
				result = simplonFeatures127.features;
			} else if (projectId == '645a371b944a3a0025233c69'){
				result = simplonFeatures119.features;
			}
			if (result) {
				return result;
			}
		}
		return undefined;
	}

	static modelPoint(object) {
		if (object) {
			let _data = {
				"title": object.name,
				"description": object.userData.codeString ? object.userData.codeString : '',
				"projectId": object.userData.projectId,
				"type": "point",
				"tag": window.projectTag,
				"code": object.userData.code ? object.userData.code : 0,
				"positionX": Number(object.points[0].position.x),
				"positionY": Number(object.points[0].position.y),
				"positionZ": Number(object.points[0].position.z),
				"km":  Number(object.userData.km),
				"image": [],
				"externalLink": null
			};
			return _data;
		} else {
			return undefined;
		}
	}
	static modelAnnotation(object) {
		if (object) {
			let _data = {
				"title": object.title,
				"description": object.codeString,
				"projectId": object.projectId,
				"type": "annotation",
				"tag": window.projectTag,
				"code": object.code,
				"positionX": Number(object.position.x),
				"positionY": Number(object.position.y),
				"positionZ": Number(object.position.z),
				"km":  Number(object.userData.km),
				"image": [],
				"externalLink": null
			};
			return _data;
		} else {
			return undefined;
		}
	}
	static async savePoint(object) {
		const objectModel = SdioFetch.modelPoint(object);
		if (objectModel) {
			let objId = undefined;
			if (object.userData.id) objId = object.userData.id;
			let result = await this.save("/dynamicdata/annotation", objectModel, objId);
			if (result) {
				object.userData.id = result.id;
				return result;
			}
		}
	}


	static async saveAnnotation(object) {
		const objectModel = SdioFetch.modelAnnotation(object);
		if (objectModel) {
			let result = await this.save("/dynamicdata/annotation", objectModel, object.id);
			if (result) {
				object.id = result.id;
				return result;
			}
		}
	}


	static async loadAnnotations(json) {
		if (json && json.length) {
			json.forEach((p) => {
				if (p && p.type == "point") {

					let position = new THREE.Vector3(p.positionX, p.positionY, p.positionZ);
					
					let newMeasurement = viewer.measuringTool.startInsertion({
						showDistances: false,
						showAngles: false,
						showCoordinates: true,
						showArea: false,
						closed: true,
						maxMarkers: 1,
						name: p.title,
						codeString: '',
						code: 0,
						projectId: SdioState.getProjectId(),
						autoLoad: true
					});
					
					newMeasurement.points[0].position.x = position.x;
					newMeasurement.points[0].position.y = position.y;
					newMeasurement.points[0].position.z = position.z;
					newMeasurement.userData.codeString = p.description ? p.description : undefined,
					newMeasurement.userData.code = p.code ? p.code : 0;
					newMeasurement.userData.km = p.km ? p.km : 0;
					newMeasurement.userData.projectId = p.projectId ? p.projectId : undefined;
					newMeasurement.userData.id = p.id ? p.id : newMeasurement.userData.id;
					p.uuid = newMeasurement.uuid;

				} else if (p && p.type == "annotation") {
					let position = new THREE.Vector3(p.positionX, p.positionY, p.positionZ);
					let cameraPosition = { x: position.x + 15, y: position.y + 6, z: position.z + 6 };
					let newAnnotation = viewer.scene.addAnnotation(position, {
						title: p.title,
						cameraPosition: cameraPosition,
						cameraTarget: position,
					});
					newAnnotation.codeString = p.description ? p.description : undefined,
					newAnnotation.code = p.code ? p.code : 0;
					newAnnotation.projectId = p.projectId ? p.projectId : undefined;
					newAnnotation.id = p.id ? p.id : newAnnotation.id;
					p.uuid = newAnnotation.uuid;
				}
			});


		}
	}


}


export class SdioState {

	// Project State
	static stateName() {
		return 'sdio-project';
	}

	static savelocalStorage(value) {
		localStorage.setItem(this.stateName(), JSON.stringify(value));
	}

	static readlocalStorage() {
		let data = localStorage.getItem(this.stateName());
		return JSON.parse(data);
	}

	static removelocalStorage() {
		localStorage.removeItem(this.stateName());
	}

	static getProjectId() {
		const project = this.readlocalStorage()
		return project.id;
	}

	// Project default Tag
	static setTag(value) {
		localStorage.setItem(this.stateName() + '-tag', JSON.stringify(value));
	}
	static getTag() {
		let data = localStorage.getItem(this.stateName() + '-tag');
		return JSON.parse(data);
	}

	// Swissdata State

	static stateNameSwissdata() {
		return 'scanways-viewer-state';
	}

	static removelocalStorageSwissdata() {
		localStorage.removeItem(this.stateNameSwissdata());
	}

	static readlocalStorageSwissdata() {
		let data = localStorage.getItem(this.stateNameSwissdata());
		return JSON.parse(data);
	}

	static getUserId() {
		const state = this.readlocalStorageSwissdata();
		if (state && state.swissdata && state.swissdata.user && state.swissdata.user.id) {
			return state.swissdata.user.id;
		} else {
			return undefined;
		}
	}
	static getUser() {
		const state = this.readlocalStorageSwissdata();
		if (state && state.swissdata && state.swissdata.user) {
			return state.swissdata.user;
		} else {
			return undefined;
		}
	}
	static getUserExpires() {
		const state = this.readlocalStorageSwissdata()
		if (state && state.swissdata && state.swissdata.accessExpires) {
			return state.swissdata.accessExpires;
		} else {
			return undefined;
		}
	}

	static saveToken(token, user) {
		let sdState = {
			"stateVersion": "2.0",
			"swissdata": {
				"authenticated": true,
				"publicKey": env.swissdata.apiKey,
				"user": user,
				"accessToken": token.token,
				"accessRefresh": token.refresh,
				"accessExpires": token.expires,
				"accessValidity": token.validity,
				"loginStep": "login",
				"h": token.refresh,
				"requireDoubleAuthValidation": false,
				"profile": {},
				"doubleAuthValidationToken": ""
			}	
		}
		// token validity delay = 14 days
		localStorage.setItem(this.stateNameSwissdata(), JSON.stringify(sdState));
	}
	
	static validUserToken(){
		let logged = false;
		let userId = this.getUserId();
		if (userId) {
			let expires = new Date(this.getUserExpires());
			let toDay = new Date();
			if (toDay < expires) {
				logged = true;
			} else {
				logged = false;
			}
		} else {
			logged = false;
		}
		return logged;
	}



}

export class SdioUtils {

	static async openEdit(object) {

		let menuAnnotationManager = $('#annotation_manager');
		const pointName = menuAnnotationManager.find("#pointName");
		// const pointCode = menuAnnotationManager.find("#pointCode");
		const pointCodeNumber = menuAnnotationManager.find("#pointCodeNumber");
		const pointCoordY = menuAnnotationManager.find("#pointCoordY");
		const pointCoordX = menuAnnotationManager.find("#pointCoordX");
		const pointCoordZ = menuAnnotationManager.find("#pointCoordZ");
		const pointKm = menuAnnotationManager.find("#pointKm");

		if (object && object instanceof Annotation) {
			pointName[0].value = object.title;
			// pointCode[0].value = object.codeString;
			pointCodeNumber[0].value = object.code;
			pointCoordY[0].value = this.round(object.position.x, 3);
			pointCoordX[0].value = this.round(object.position.y, 3);
			pointCoordZ[0].value = this.round(object.position.z, 3);
			window.currentObject = object;
		} else if (object instanceof Measure && object && object.type === "Object3D" &&
		    object.name != 'Distance' && object.name != 'Height' && object.name != 'Angle') {

			pointName[0].value = object.name;
			// pointCode[0].value = object.userData.codeString;
			pointCodeNumber[0].value = object.userData.code;
			pointCoordY[0].value = this.round(object.points[0].position.x, 3);
			pointCoordX[0].value = this.round(object.points[0].position.y, 3);
			pointCoordZ[0].value = this.round(object.points[0].position.z, 3);


			// Calculate KM
			pointKm[0].value = object.userData.km;

			// Load data
			if (!featuresSdio) featuresSdio = await SdioFetch.loadAxisFeatures(window.currentProject.id);
			if (featuresSdio) {
				let features = featuresSdio;
				if (features && features.length > 0 && features[0].properties.km) {
					const points = features[0].geometry.coordinates;
					const startKm = features[0].properties.km;
					pointKm[0].value = (startKm + (Utils.distanceCumulative(points, [pointCoordY[0].value, pointCoordX[0].value]) / 1000)).toFixed(4);
				}
			}

			window.currentObject = object;
			
			
			// Zoom
			// if (object.points[0].position.x + object.points[0].position.y + object.points[0].position.z != 0){
			// 	const box = new THREE.Box3();
			// 	box.min = new THREE.Vector3(object.points[0].position.x - 1,object.points[0].position.y - 1,object.points[0].position.z - 1);
			// 	box.max = new THREE.Vector3(object.points[0].position.x + 1,object.points[0].position.y + 1,object.points[0].position.z + 1);
			// 	object.boundingBox = box;
			// 	viewer.zoomTo(object, 3, 0);
			// 	viewer.controls.stop();
			// }

			console.log('edit object', object);
		}
	}

	static addPointsMultiple() {

		window.pointIncNumber++
		window.newObjectAdding = true;

		// Set default
		let menuAnnotationManager = $('#annotation_manager');
		const pointCodeLabel = '';// menuAnnotationManager.find("#pointCode");
		const pointCodeNumberLabel = menuAnnotationManager.find("#pointCodeNumber");
		let pointCode = ''; //pointCodeLabel[0].value;
		let pointCodeNumber = pointCodeNumberLabel[0].value;
		this.setDefaultVariable(pointCode, pointCodeNumber);
		

		$('#menu_measurements').next().slideDown();
		let measurement = viewer.measuringTool.startInsertion({
			showDistances: false,
			showAngles: false,
			showCoordinates: true,
			showArea: false,
			closed: true,
			maxMarkers: 1,
			name: window.pointIncNumber,
			codeString: '',
			code: pointCodeNumber,
			projectId: SdioState.getProjectId()
		});

		// let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
		// let jsonNode = measurementsRoot.children.find(child => child.data.uuid === measurement.uuid);
		// $.jstree.reference(jsonNode.id).deselect_all();
		// $.jstree.reference(jsonNode.id).select_node(jsonNode.id);
	}



	static round(value, decimal) {
		return Number.parseFloat(value).toFixed(decimal);
	}

	static setDefaultVariable(pointCode, pointCodeNumber) {
		if (pointCode != undefined && pointCode != ""){
			window.pointCodeDefault = pointCode;
		}
		if (pointCodeNumber != undefined && pointCodeNumber != "" && pointCodeNumber != 0){
			window.pointCodeNumberDefault = pointCodeNumber;
		}
	}

}
