
export class CSVExporter {
	static toString (points) {
	
		let string = '';

		string = 'name,codeString,code,X,Y,Z,KM,' + '\n';

		for (const point of points) {
			let values = [];
			values.push(point.name);
			values.push(point.userData.codeString ? point.userData.codeString: '');
			values.push(point.userData.code ? point.userData.code : '');
			let p = {x:0, y: 0, z: 0};
			if (Array.isArray(point.points) & point.points.length > 0 ) p = point.points[0].position;
			values.push(p.x);
			values.push(p.y);
			values.push(p.z);
			values.push(point.userData.km ? point.userData.km : '');
			// values.push(point.userData.projectId ? point.userData.projectId : '');
			// values.push(point.userData.type ? point.userData.type : '');
			// values.push(point.userData.tag ? point.userData.tag : '');
			string += values.join(',') + '\n';
		}
			
		return string;

	};

};
